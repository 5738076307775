.doodles__root {
  .header {
    &__container {
      width: 100%;
      max-width: 1600px;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 2rem;

      display: grid;
      grid-template-rows: 200px auto;
      color: grey;

      &--hero {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
        object-position: top center;
      }
    }

    &__content {
      display: grid;
      width: 100%;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: "avatar text" "stats stats";
      background-color: white;
      padding-bottom: 30px;
      position: relative;
    }

    &__title {
      margin: 0;
      color: black;
      font-size: 24px;
    }

    &__subtitle {
      margin-bottom: 10px;
      a {
        color: #a4a4f4;
      }
    }

    &__text {
      grid-area: text;
      padding: 20px;
      padding-left: 0;
      color: #333;

      p {
        margin: 0;
      }
    }

    &__description {
      color: #333;
    }

    &__socials {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      justify-content: flex-end;
      color: #333;

      i {
        color: #a4a4f4;
        transition: all ease-in-out 0.25s;
        &:hover {
          transform: scale3d(1.3, 1.3, 1.3) rotate3d(1, 0, 0, -7deg);
        }
      }
    }

    &__stats {
      grid-area: stats;
    }

    &__avatar {
      grid-area: avatar;
      padding: 0 30px;

      img {
        --half-size: -60px;
        --size: 120px;

        height: var(--size);
        width: var(--size);
        overflow: hidden;
        border-radius: 50%;
        border: 6px solid white;
        transform: translateY(var(--half-size));
      }
    }
  }
}
