

.nav-search {
    width: 50%;
    border-radius: 34px;
    background: url('./search.svg') no-repeat;
    background-size: 20px;
    background-position: 98%;


    

}
