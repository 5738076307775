.doodles__filters-list{
  .divider{
    width: 2px;
    align-self: stretch;
    background-color: white;
    margin: 0 0.25rem;
    opacity: 0.5;
  }
}

button.top-filter {
  border: none;
  color: white;
  border-radius: 8px;
  box-shadow: 0 2px #c76291;
  padding: 0.25rem 0.75rem;
  padding-right: 2rem;
  background-color: #ec7cb7;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  transition: all ease-in-out 0.15s;
  animation: slide-in 0.25s;

  &:hover{
    transform: translate3d(0,-3px,0);
    box-shadow: 0 4px #b95484;
  }

  &:after{
    content: "x";
    position: absolute;
    right: 0.5rem;
    font-size: 1.25em;
    opacity: 0.35;
    top: 0;
  }
}

@keyframes slide-in {
  from{
    transform: translate3d(0,-10px,0);
    opacity: 0;
  }
  to{
    transform: translate3d(0,0,0);
    opacity: 1;
  }
}
