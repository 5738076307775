#col-detail-container{

}

#col-detail-card{
  width: 100%;
}

.detail-nft {
  width: 100%;
  /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
  border-radius: 8px;
}
@media only screen and (max-width: 576px) {
  .detail-nft {
    /* width: 155px; */
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
    border-radius: 8px;
  }
}

#detail-tabs .profiletabs.nav {
  flex-wrap: inherit;
}
.detail-nft img {
  border-radius: 8px;
  border-width: 2px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  aspect-ratio: 1/1 !important;
}

.detail-nft-description {
  color: var(--text-secondary);
  white-space: pre-line;
}

.detail-nft video {
  border-radius: 8px;
  border-width: 2px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  aspect-ratio: 1/1 !important;
}
.detail-nft-selected {
  border-color: black;
  filter: none !important;
}
.text-nft-detail {
  white-space: pre;
  text-align: center;
  height: auto;
}
.text-nft-wrapper-detail {
  min-height: 300px;
  display: flex;
}

.nft-details-card {
  background-color: var(--background-profile);
}


.nft-royalties-card{
  background-color: var(--background-tertiary) !important;

}


#detail-acc.accordion .accordion-item,
.accordion-header,
.accordion-button {
  background-color: transparent !important;
  color: var(--text-primary);
  border: none;
}
#detail-acc .accordion-button::after {
  background-image: var(--accordion-chevron);
}
#detail-acc .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
#detail-acc .accordion-button:not(.collapsed) {
  color: var(--theme-main);
}

.collection-name:hover {
  color: var(--theme-main);
}
