#profile-collection-tab-wrapper {
  min-height: 600px;
  .hidden {
    display: none;
  }

  #profile-collection-wallet-tabs-wrapper {
    #em-id-powered-label-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1rem;
      padding-left: 1rem;
      padding-top: 1rem;

      img {
        width: 2rem;
        height: 2rem;
      }
      h4 {
        margin-bottom: 0;
      }
    }

    #send-batch-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 1rem;
      padding-left: 1rem;
      padding-top: 1rem;
    }
    .nav-tabs {
      border-bottom: none !important;
      margin-top: 1rem;
      transition: all ease 1s;
      margin-bottom: 1rem;

      margin-left: 1rem;
      margin-right: 1rem;
    }
    .profile-collection-wallet-tabs {
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        background-color: var(--background);
        border-radius: 10px !important;
      }
      .nav-link.active {
        background-color: var(--theme-main);
        border-color: var(--theme-main-light);
      }

      .nav-item{
        button:hover {
         border: 1px solid var(--theme-main-light)
        }
      }
      #wallet-type-tab-img {
        height: 3rem;
        width: 3rem;
        border-radius: 6px;
      }
    }

    .hidden-nav {
      .nav-link {
        display: none !important;
      }
    }

    .viewing-span {
      font-size: 0.9rem;
      color: var(--text-secondary);
    }

    .collection-tabs {
      .nav-item {
        display: none;
      }
    }
  }
}
