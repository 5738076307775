.row__clear-margins{
  margin: calc(-1 * var(--bs-gutter-y)) 0 0;
}

body.no-scroll{
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

// Doodles Marketplace Layout
.doodles {
  &__root {
    background-color: #99e2ff;
    width: 100%;
    padding: 1rem;
    margin: 0;
    font-family: "Chalkboard", cursive;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  &__grid {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;

    align-items: start;
    display: grid;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
    grid-template-rows: auto 1fr;
    min-height: 75vh;

    grid-template-columns: 1fr;
    grid-template-areas: "selection" "results";
  }

  &__results {
    --min: 35%;
    --max: 1fr;

    grid-column: 2;
    overflow: hidden;
    padding: 0;
    display: grid;
    gap: 20px;
    width: 100%;
    grid-area: results;
    grid-template-columns: 100%;
    align-items: start;
    margin: 0 auto;
    --saturation: 1;
    --opacity: 1;

    /*Focus Mode
    &:hover .doodle:not(:hover) {
      --saturation: 0.5;
      --opacity: 0.35;
    }
    */
  }
}
