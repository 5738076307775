$dropPageAccent: #00c6d2;
$headerFontFamily: "K2D";

.drop-page {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 100%;
    background-color: var(--background-profile);

    a {
      text-decoration: none;
    }
  }

  &-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 3rem 0 1rem;
    width: 100%;
    box-sizing: border-box;

    .new-drop-span-wrapper {
      display: flex;
      align-items: center;
      background-color: var(--theme-main-light);
      padding: 0.2rem;
      border-radius: 25px;
      font-size: 0.8rem;
      opacity: 1;

      .new-drop-span {
        background-color: var(--background);
        border-radius: 25px;
        padding: 0.1rem 0.6rem;
      }
    }

    .header-title-span {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
  &-mint-box {
    &-mint {
      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #133840;
        border: 3px solid #000000;
        border-radius: 24px;
        margin-bottom: 2rem;
        padding: 2rem;
        scroll-padding: 96px !important;
      }
      &-price-span {
        color: #ffdb1e;
        font-family: "Mitr", sans-serif;
        font-weight: 500;
        font-size: 2rem;
      }
      &-purchase-span {
        color: #ffffff;
        font-family: "Mitr", sans-serif;
        font-size: 3rem;
      }
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: black;
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      text-align: center;
      padding: 4rem 0;
    }
    &-image-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 2rem;

      img {
        border-radius: 10px;
        max-width: 100%;
        width: 100%;
      }
    }
    &-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 2rem;
      max-width: 1280px;
    }
    &-title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: #133840;
      border: 3px solid #000000;
      border-radius: 24px;
      margin-bottom: 2rem;
      padding: 2rem;
      mix-blend-mode: normal;
      border: 3px solid #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        inset -17px 17px 23px rgba(0, 0, 0, 0.25);
    }
    &-splash {
      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      &-title-span {
        color: #00f85d;
        font-family: $headerFontFamily;
        font-size: 5rem;
        text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
      }
      &-subtitle-span {
        color: #ffdb1e;
        font-family: "Mitr", sans-serif;
        font-weight: 500;
        font-size: 2.5rem;
      }
      &-desc-span {
        color: #ffbef7;
        font-family: "Mitr", sans-serif;
        font-size: 1.5rem;
        line-height: 1.35;
        font-weight: 200;
        max-width: 40em;
      }
    }
  }
  &-info-section {
    &-wrapper {
      display: flex;
      width: 100%;
      background-color: black;
      padding: 2rem 0;
      max-width: 1280px;
      &:nth-child(2n) {
        .drop-page-info-section-text {
          order: 2;
        }
      }
    }
    &-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 2rem;
      place-items: center;
    }
    &-desc-span {
      color: #ffffff;
      font-family: "Mitr", sans-serif;
      font-size: 1.35rem;
      font-weight: 300;
      text-align: left;
    }
    &-title-span {
      color: $dropPageAccent;
      font-family: $headerFontFamily;
      font-size: 3rem;
      max-width: 8em;
      margin: 0 auto;
      line-height: 1.35;
      font-weight: 300;
    }
    &-text {
      padding: 2rem;
    }
    &-img {
      max-width: 35vw;
      &.glow {
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 0 20px 1px rgb(0 196 208 / 60%);
      }
    }
  }
  &-banner-bio-wrapper {
    padding: 2rem;
    box-sizing: border-box;
    margin-top: 2rem;
    margin-bottom: 2rem;

    img{
      height: 200px;
      flex: 0 0 auto;
      align-self: center;
    }

    .links-col {
      margin-top: 1rem;
      margin-bottom: 1rem;

      .link-item {
        border: 1px solid grey;
        border-radius: 8px;
        padding: 0.4rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-secondary);
        a {
          text-decoration: none;
        }
      }
    }
  }
  &-info-wrapper{
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
  }
}

.mint-box {
  &-title-span {
    color: $dropPageAccent;
    font-family: $headerFontFamily, sans-serif;
    font-size: 5rem;
    text-shadow: 5px 6px 4px rgba(0, 0, 0, 0.25);
  }

  &-subtitle-span {
    color: #ffffff;
    font-family: "Mitr", sans-serif;
    font-size: 1.25rem;
    font-weight: 300;
  }

  &-drop-name {
    color: $dropPageAccent;
    font-family: $headerFontFamily, sans-serif;
    font-size: 5rem;
    line-height: 1;
    margin-bottom: 2rem;
  }

  &-button-row {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  &-button-row-col {
    select {
      width: 100%;
      border-radius: 8px !important;
    }
    button {
      width: 100%;
      margin: 0;
    }
  }
}

.packs-available-span {
  color: #ffffff;
  font-family: "Mitr", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  margin-bottom: 1rem;
  display: block;
  color: #72888c;
}


@media only screen and (max-width: 480px) {
  .drop-page-banner-bio-wrapper{
    img{
      width: 100%;
      height: auto;
    }
  }
  .drop-page-mint-box-content-container{
    padding: 0 1rem;
  }
  .drop-page-mint-box-wrapper{
    padding: 2rem 0;
  }
  .drop-page-mint-box-mint-purchase-span{
    font-size: 2rem;
  }
  .drop-page-mint-box-image-wrapper{
    margin-bottom: 1rem;
  }
  .drop-page-mint-box-mint-wrapper{
    padding: 1rem;
    margin-bottom: 0;
  }
  .packs-available-span{
    font-size: 1.25rem;
  }
  .mint-box-drop-name{
    font-size: 3rem;
    line-height: 1.3;
  }
  .mint-box-button-row{
    width:100%;
  }
  .drop-page {
    &-header-card{
    }
    &-mint-box{
      &-splash {
        &-title-span {
          font-size: 3rem;
          line-height: 1;
          margin-bottom: 0.25em;
          display: block;
        }
        &-desc-span{
          font-size: 1.25rem;
          width: 100%;
        }
      }
    }

    &-info-section {
      &-wrapper {
        margin-bottom: 1rem;
        padding: 0;

        &:nth-child(2n) {
          .drop-page-info-section-text {
            order: 0;
          }
        }
      }
      &-text{
        padding: 1.5rem;
      }
      &-desc-span{
        text-align: left;
        line-height: 1.25;
        font-size: 1.25rem;
        font-weight: 300;
      }
      &-title-span{
        max-width: 100%;
        width: 100%;
        display: block;
        line-height: 1.2;
        margin-bottom: 1rem;
      }
      &-row{
        display: flex;
        flex-direction: column;

        img{
          max-width: 75%;
        }
      }
    }
  }
}
