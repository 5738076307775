@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

.dp {
  &__price {
    font-weight: 600;
    color: black;
    font-family: Roboto, sans-serif;
    &--label {
      line-height: 20px;
      font-size: 12px;
    }

    &--value {
      font-size: 24px;
      font-family: Inter, sans-serif;
    }
  }
  &__token {
    display: flex;
    padding: 4px 8px 4px 4px;
    background-color: white;
    border-radius: 16px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    font-size: 12px;
    img {
      width: 16px;
      height: 16px;
    }
  }
}

[data-theme="dark"] {
  .dp {
    &__price {
      color: #e3eae8;
    }
    &__token {
      color: #e3eae8;
      background-color: #717c78;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dp {
    &__price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      margin: 0 auto 1rem;
      width: 100%;
    }

    &__price-block {
      &--controls {
        margin: 0 auto;
        width: 100%;
        justify-content: space-between;

        button.dp__small-hero{
          padding: 1em 2.5em;
        }
      }
    }
  }
}
