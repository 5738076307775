


#list-nft .form-check-label {
  margin-top: 9px;
}

#list-nft img {
  border-radius: 8px;
  border-width: 2px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  aspect-ratio: 1/1 !important;
}

#list-nft .listedCard {
  background-color: var(--background-profile) !important;
  text-align: center;
}

.modal-content {
  background-color: var(--background);
  color: var(--text-primary);
  border-radius: 10px;
}

#list-button-row {
  display: flex;
  width: 100% !important;
  button {
    width: 100% !important;
  }
}

.list-sale-select-wrapper {
      background-color: var(--background) !important;
      margin-bottom: 1rem;
      border-radius: 8px !important;
      padding: 8px 12px !important;
      border: 1px solid #d0d5dd !important;
      box-shadow: none !important;
      outline: none !important;
      color: var(--text-secondary) !important;

      :focus {
        border-radius: 8px !important;
      }
}

#list-sale-wrapper {
  #list-currency-select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    .is-valid{
      border: 1px solid var(--theme-main) !important;
    }
    .is-invalid{
      border: 1px solid red !important;
    }
  }
  #list-amount-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    input,
    input:focus,
    input:hover,
    input:focus-visible {
      background-color: var(--background) !important;
      margin-bottom: 1rem;
      border-radius: 8px;
      padding: 8px 12px !important;
      border: 1px solid #d0d5dd;
      box-shadow: none !important;
      outline: none !important;
      color: var(--text-primary);
    }

    label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    .is-valid{
      border: 1px solid var(--theme-main) !important;
    }
    .is-invalid{
      border: 1px solid red !important;
    }
  }
}

#list-auction-wrapper {
  #auction-description-row {
    font-size: 0.9rem;
    color: var(--text-secondary) !important;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  #auction-input-row {
    display: flex;
    max-width: 100% !important;

    .auction-input-wrapper {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      label {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
      }
      input,
      input:focus,
      input:hover,
      input:focus-visible {
        background-color: var(--background) !important;
        margin-bottom: 1rem;
        border-radius: 8px;
        padding: 8px 12px !important;
        border: 1px solid #d0d5dd;
        box-shadow: none !important;
        outline: none !important;
        color: var(--text-primary);
        width: 100% !important;
      }

      .is-valid{
        border: 1px solid var(--theme-main) !important;
      }
      .is-invalid{
        border: 1px solid red !important;
      }
    }
  }

  .auction-input-wrapper {
    display: flex;
    flex-direction: column;
    label {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    select,
    select:focus,
    select:hover,
    select:focus-visible {
      background-color: var(--background) !important;
      margin-bottom: 1rem;
      border-radius: 8px !important;
      padding: 8px 12px !important;
      border: 1px solid #d0d5dd !important;
      box-shadow: none !important;
      outline: none !important;
      color: var(--text-secondary) !important;
    }
  }
}
