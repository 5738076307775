
#edition-span{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--text-tertiary);
    font-size: 0.7rem;
    font-weight: 500;
    padding: 4px;
    // color: black;
}