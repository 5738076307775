.sticker-box{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
}

.sticker-span{
    display: flex;
    background-color: var(--background-profile);
    padding: 0.25rem 0.5rem;
    border-radius: 20px;
    align-items: center;
    gap: 5px;
}

.sticker-span-no-bg{
    display: flex;
    background-color: var(--background-navigation);
    padding: 0.25rem;
    border-radius: 20px;
}

.sticker-span-tertiary{
    display: flex;
    background-color: var(--background-tertiary) !important;
    padding: 0.25rem;
    border-radius: 20px;
}

.sticker-image{
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
}

.ft-span{
    margin-top: 1.1px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
}