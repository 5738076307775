#helphub-side-nav-card {
    height: auto;
    position: fixed;
}

#helphub-side-nav-card a{
    text-decoration: none;
}

#helphub-side-nav-mobile-card {
    height: auto;
    width: 90%;
}

#helphub-side-nav-mobile-card a{
    text-decoration: none;
}

#helphub-side-nav-mobile-container{
    display: none;
}

@media screen and ( max-height: 750px ) { 
#helphub-side-nav-col{
    display: none;
}

#helphub-side-nav-mobile-container{
    display: flex;
}
}

@media screen and ( max-width: 990px ) { 
    #helphub-side-nav-container{
        display: none;
    }
    
    #helphub-side-nav-mobile-container{
        display: flex;
    }
    }
