.dp {
  &__icon-container {
    display: grid;
    place-items: center;
    width: 16px;
    height: 16px;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
    }

    svg{
      fill: black;
    }
  }
}

[data-theme="dark"] {
  .dp {
    &__icon-container {
      svg{
        fill: #e3eae8;
      }
    }
  }
}
