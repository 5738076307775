.history-container {
  width: 100%;
  margin: 0 auto;
}

.transfer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  border-radius: 10px;
  position: relative;
  border: 1px solid var(--theme-main);
  background-color: var(--background-tertiary);
}

div.transfer-container{
  padding-right: 3.5rem !important;
}

.transfer-container textarea {
  min-height: 300px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 4px;
  overflow-y: scroll;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 3px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.transfer-container p {
  margin-bottom: 0;
}

.transfer-container textarea:last-child {
  margin-bottom: 0;
}

.event-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 14px;
}
.event-block:last-of-type {
  margin-bottom: 24px;
}

.event-block p {
  margin-bottom: 0;
}

.event-id {
  font-size: 16px;
  font-weight: bold;
  color: #535bf2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.related,
.related p,
.related header {
  color: deeppink;
}

.transfer__debug {
  --size: 32px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 12px;
  right: 16px;

  width: var(--size);
  height: var(--size);
  border-radius: var(--size);

  background-color: #c489ff;
  border: 2px solid #9757cf;
  box-shadow: 0 2px 5px rgb(106 56 143 / 25%);
  cursor: not-allowed;
}

.transfer-container__timestamp {
  word-break: break-word;
  color: var(--text-secondary);
  font-size: small;
}

.transfer-container__title {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0.25em;
}

.transfer-container__single {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.transfer-container__single__amount {
  font-size: calc(1rem + 0.6vw);
  color: black;
}

.transfer-container__ticker {
  text-transform: uppercase;
  font-weight: 700;
  color: var(--text-secondary);
}

.find-address {
  color: #50b594;
}
