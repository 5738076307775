.shareIcons {
  color: var(--text-secondary);
}

.shareIcons:hover {
  color: var(--theme-main);
}

#collection-img {
  height: auto;
  width: 48px !important;
  border-radius: 50%;
  /* box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15); */
  transition: 0.5s;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  object-fit: fill !important;
}

#offers-accordion-header {
  background-color: var(--background-tertiary) !important;
  border-radius: 10px !important;
  border: 1px solid #d7d7d7 !important;
}

.latestOffersCard {
  background-color: var(--background-) !important;
  border: none !important;
  /* border: 1px solid var(--theme-main) !important */
}

#offers-accordion-header .accordion-button:not(.collapsed) {
  color: var(--theme-main);
  background-color: #e7f1ff;
  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}

#offers-accordion-header .accordion-button:focus {
  z-index: 3;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-button::after {
  background-image: var(--accordion-chevron) !important;
}

@media only screen and (max-width: 576px) {
  .likes-icon-box {
    margin-right: 1rem;
  }
}
