#store-filter-form {
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-primary);
    border: none;
    box-shadow: none;
}

.store-table{
    table-layout: fixed;
    width: 100%;
}

.store-table a{
    text-decoration: none;
}

.store-row {
 height: 60px;
 border: none;

}

.store-thumb-image{
    height: 50px;
    width: 50px;
    border-radius: 10px;

}

.store-thumb-col{
    width: 10% !important;
}

.store-name-col{
    width: 20% !important;
}

.store-sticker-col{
    width: 20% !important;
    text-align: start;

    
}

.store-sticker{
    font-size: 10px;
    background-color: var(--background-profile);
    border-radius: 20px;
    padding: 5px;
}

.store-time-left-col{
    width: 15% !important;
    text-align: center;
}

.store-price-col{
    width: 21% !important;
    color: var(--text-secondary);


}

.store-buttons-col{
width: 25% !important;
text-align: right;
}    

.mobile-row{
    display: none;
}



@media screen and (max-width:768px) {
    .mobile-row{
        display: table-row;
    }  
    .store-thumb-col{
    width: 10%;
    text-align: center;
        }

    .store-buttons-col{
        display: none;
        }   
        .store-sticker{}

    .store-sticker-col{
    display:none;
    }
    .store-price-col{
        text-align: end;
    
    }

    
  .store-time-left-col{
    display:none;
    }
  
    .mobile-store-sticker-col{
        text-align: center;
    }

    .mobile-store-time-left-col{
        text-align: start;
    }

    .mobile-store-buttons-col{
        text-align: end;

    }}

