.collection-tabs.nav {
    flex-wrap:wrap !important; 
}
@media only screen and (max-width: 576px) {
    .collection-tabs.nav {
        flex-wrap: inherit !important; 
    }
    .collection-tabs.nav.nav-tabs {
display: inline-flex;
border: none !important;
border-radius: 10px;
background-color: transparent !important;
width: 100%;
overflow-x: auto;
overflow-y: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
overflow: -moz-hidden-unscrollable;
box-shadow:none !important;
margin-bottom: 1rem;
}
}
.collection-tabs.nav.nav-tabs {
display:none !important;
border: none !important;
border-radius: 10px;
background-color: transparent !important;
width: 100%;
overflow-x:none !important;
overflow-y: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
overflow: -moz-hidden-unscrollable;
box-shadow:none !important;
margin-bottom: 1rem;
}
.collection-tabs li:last-child {
margin-right: 9px;
}
.collection-tabs.nav-tabs .nav-link {
color: var(--text-primary);
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
height: 33px;
padding: 4px 16px !important;
}
.collection-tabs.nav-tabs .nav-link.active {
background-color: #f6f6f6 !important;
color: black !important;
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
height: 33px;
}
.collection-tabs.nav-tabs .nav-link:hover {
/* background-color: var(--theme-main); */
/* color: var(--text-primary-oppo); */
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
height: 33px;
}
.collection-tabs.nav-tabs .nav-link:focus {
/* background-color: var(--theme-main); */
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
height: 33px;
}





.album-tabs.nav {
    flex-wrap:wrap !important; 
}
@media only screen and (max-width: 576px) {
    .album-tabs.nav {
        flex-wrap: inherit !important; 
    }
    .album-tabs.nav.nav-tabs {
display: inline-flex;
border: none !important;
border-radius: 10px;
background-color: transparent !important;
width: 100%;
overflow-x: auto;
overflow-y: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
overflow: -moz-hidden-unscrollable;
box-shadow:none !important;
margin-bottom: 1rem;
}
}
.album-tabs.nav.nav-tabs {
display:inline-flex !important;
border: none !important;
border-radius: 10px;
background-color: transparent !important;
width: 100%;
overflow-x:none !important;
overflow-y: hidden;
-ms-overflow-style: none;
scrollbar-width: none;
overflow: -moz-hidden-unscrollable;
box-shadow:none !important;
margin-bottom: 1rem;
}
.album-tabs li:last-child {
margin-right: 9px;
}
.album-tabs.nav-tabs .nav-link {
color: var(--text-primary);
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
/* height: 33px; */
padding: 4px 16px !important;
}
.album-tabs.nav-tabs .nav-link.active {
background-color: #f6f6f6 !important;
color: black !important;
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
/* height: 33px; */
}
.album-tabs.nav-tabs .nav-link:hover {
/* background-color: var(--theme-main); */
/* color: var(--text-primary-oppo); */
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
/* height: 33px; */
}
.album-tabs.nav-tabs .nav-link:focus {
/* background-color: var(--theme-main); */
border-width: 0px;
border-radius: 10px;
margin-left: 9px;
margin-top: 8px;
margin-bottom: 8px;
/* height: 33px; */
}

#collections .form-select {
    background-color: var(--background-profile) !important;
    border: none !important;
    height: 50px !important;
    border-radius: 10px !important;
    border: none !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}

#collections .form-select:hover {
    filter: brightness(0.95);
    cursor: pointer;
}


.file {
    background: 'linear-gradient(to bottom, #EEE, #DDD)';
    border-radius: 20;
    display: 'flex';
    height: 120;
    width: 120;
    position: 'relative';
    z-index: 10;
    flex-direction: 'column';
    justify-content: 'center';
}

.select-nft {
    /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
    border-radius: 8px;
    background-color: var(--background-profile);
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}

.select-nft-border {
    border-radius: 8px;
    background-color: var(--background-profile);
    position: relative;
    top: 0;
    transition: top ease 0.5s;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: white !important;
}

.select-nft:hover {
    top: -5px;
    /* -webkit-filter: brightness(110%); */
    transform: scale(1.01);
    cursor: pointer;
    /* border: 2px solid white; */
}


@media only screen and (max-width: 576px) {
    .select-nft {
        /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
        border-radius: 8px;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
}

.select-nft-border img {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
    padding: 10px;
}
.select-nft-border video {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}

.select-nft img {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
    padding: 10px;
}
.select-nft video {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}