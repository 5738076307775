.integrate-image-wrap{
    height: 64px !important;
    width: 64px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.integrate-image-wrap img{
    border-radius: 12px;
    height: 62px !important;
    width: 62px !important;
}

.integrate-label{
    color: var(--text-secondary);
}