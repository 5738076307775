.tags-input-container{
    padding: 10px;
    width: 100%;
    background: var(--background);
    color: var(--text-primary);
    box-shadow: none;
    border-radius: 12px !important;
    border: 1px solid #ced4da;
    min-height: 60px;
}

.tags-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.tag-item{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary);
    font-size: 15px;
    background-color: var(--background-profile);
    margin-left: 5px; 
    /* border: 2px, solid; */
    border-radius: 8px;
    padding-left: .3em;
    padding-right: .3em;
    border: 1px solid var(--text-secondary) !important;
}


.tag-close{
padding-left: .5em;
font-size: 25px;
cursor: pointer;
}

.tags-input{
    border: none;
}

.tags-input:focus{
    border: none;
    box-shadow: none;
}