@media only screen and (min-width: 960px) {
  .doodles {
    &__grid {
      &.show-filters{
        .filter{
          width: 350px;
        }
      }
      .filter {
        width: 0;
        padding: 0;
        position: sticky;
        top: 108px;
        background: none;
        z-index: 1000;

        &__container{
          height: 100%;
        }

        &__content{
          flex:1;
          margin-bottom: 1rem;
        }

        &__search-title{
          span{
            display: none;
          }
        }
      }
    }
  }
}

