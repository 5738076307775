#send-item-modal {
  #send-item-info-wrapper {
    #send-item-icon-row {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
      }
      #send-arrows-img {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
    #send-item-name-row {
      margin-bottom: 1rem;
      font-size: 1.1rem;
      font-weight: 600;
    }
    #send-item-desc-row {
      margin-bottom: 2rem;
      color: var(--text-secondary);
      font-size: 0.9rem;
    }

    #send-item-form-wrapper {
      #send-item-form {
        display: flex;
        flex-direction: column;

        input,
        input:focus,
        input:hover,
        input:focus-visible {
          background-color: var(--background) !important;
          margin-bottom: 2rem;
          border-radius: 8px;
          padding: 8px 12px !important;
          border: 1px solid #d0d5dd;
          box-shadow: none !important;
          outline: none !important;
          color: var(--text-primary);
        }

        textarea,
        textarea:focus,
        textarea:hover,
        textarea:focus-visible {
          background-color: var(--background) !important;
          margin-bottom: 2rem;
          border-radius: 8px;
          padding: 8px 12px !important;
          border: 1px solid #d0d5dd;
          box-shadow: none !important;
          outline: none !important;
          min-height: 20vh;
          color: var(--text-primary);
        }

        label {
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
          font-weight: 500;
          color: var(--text-secondary);
        }
      }
    }

    #send-item-button-row {
      display: flex;
      width: 100% !important;
      button {
        width: 100% !important;
      }
    }
  }

  #send-item-confirm-wrapper {
    #send-item-icon-row {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        width: 3rem !important;
        height: 3rem !important;
        margin-right: 0.5rem;
      }
      #send-arrows-img {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
    #send-item-name-row {
      margin-bottom: 1rem;
      font-size: 1.1rem;
      font-weight: 600;
    }
    #send-item-receiver-info {
      margin-bottom: 1rem;
      #receiver-title {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: var(--text-secondary);
      }
    }
    #send-item-memo-info {
      margin-bottom: 1rem;
      #message-title {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: var(--text-secondary);
      }
    }

    #send-item-error-info {
      margin-bottom: 1rem;
    }

    #send-item-confirm-summary {
      margin-bottom: 1rem;
      #send-item-confirm-summary-title {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: var(--text-secondary);
      }
    }

    #send-item-confirm-royalties {
      margin-bottom: 1rem;
      #send-item-confirm-royalties-title {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: var(--text-secondary);
      }
      #royalty-names {
        margin-top: 1rem;
        color: var(--text-secondary);
        font-weight: 500;
      }
      #royalty-select-form {
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        flex-direction: column;
        margin-top: 1rem;
        .check-wrapper {
          display: flex;
          align-items: center;
          padding: 1rem;
          border: 1px solid #d0d5dd;
          border-radius: 12px;
          margin-bottom: 0.3rem;
          margin-top: 0.3rem;
          color: var(--text-secondary);
          font-weight: 500;
          label {
            margin-bottom: 0 !important;
            margin-left: 0.5rem !important;
          }
          input {
            border-color: #d1fada !important;
          }

          input:checked {
            color: var(--theme-main);
            border-color: var(--theme-main) !important;
            background-color: #e4f5ee !important;
            background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='%2358B792'/%3E%3C/svg%3E") !important;
            background-size: 6px;
          }
        }
        #amount-input {
          width: 50px;
          background-color: var(--background);
          color: var(--text-primary);
          border: 1px solid #d0d5dd !important;
          border-radius: 12px;
          padding: 0.5rem;
          outline: none !important;
        }
        #other-amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          #other-inner {
            display: flex;
          }
        }
      }
    }

    #send-item-button-row {
      display: flex;
      width: 100% !important;
      margin-bottom: 2rem;
      button {
        width: 100% !important;
      }
    }
  }
}
