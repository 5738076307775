#profile-tabs .profiletabs.nav {
        flex-wrap: inherit; 
}
#profile-tabs .profiletabs.nav.nav-tabs {
    display: inline-flex;
    border: none !important;
    border-radius: 10px;
    background-color: var(--background);
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-hidden-unscrollable;
    box-shadow: var(--shadow);
    margin-bottom: 1rem;
}
#profile-tabs .profiletabs li:last-child {
    margin-right: 9px;
}
#profile-tabs .profiletabs.nav-tabs .nav-link {
    color: var(--text-primary);
    border-width: 0px;
    border-radius: 26px;
    margin-left: 9px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 33px;
    padding: 4px 16px !important;
}
#profile-tabs .profiletabs.nav-tabs .nav-link.active {
    background-color: var(--theme-main);
    color: var(--text-primary-oppo);
    border-width: 0px;
    border-radius: 26px;
    margin-left: 9px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 33px;
}
#profile-tabs .profiletabs.nav-tabs .nav-link:hover {
    /* background-color: var(--theme-main); */
    /* color: var(--text-primary-oppo); */
    border-width: 0px;
    border-radius: 26px;
    margin-left: 9px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 33px;
}
#profile-tabs .profiletabs.nav-tabs .nav-link:focus {
    /* background-color: var(--theme-main); */
    border-width: 0px;
    border-radius: 26px;
    margin-left: 9px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 33px;
}

#profile-tabs a {
   text-decoration: none;
}