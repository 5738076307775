.dp {
  &__progress {
    &--container {
      width: 100%;
      position: relative;
      border-radius: 4px;
      background-color: #eeeeee;
      height: 8px;
      box-sizing: border-box;
      overflow: hidden;
    }
    &--fill {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      border-radius: 3px;
      background-color: #58b792;
      height: 100%;
      transition: all 0.5s ease-in-out;
    }
  }
}

[data-theme="dark"] {
  .dp {
    &__progress {
      &--container {
        background-color: #4a524f;
      }
    }
  }
}
