#collection-stats-row{
    border: 1px solid #D3D3D3;
    border-radius: 10px;
    padding: 0.3rem;
    text-align: center;
}

#collection-stats-row .stats-title{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary);
    font-weight: 500;
    font-size: small;
    text-align: center;

}

#collection-stats-row .stats-num{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-align: center;
}

#collection-header .collection-description{
    font-weight: 400;
}

#collection-header .icon-span{
    display: flex;
    justify-content: center;
    align-items: center;
}

#collection-header .icon-span i{
    color: var(--text-secondary)
}

#collection-header .icon-span i:hover{
    color: var(--theme-main)
}

#collection-header .header-card-bg {
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 382px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}

#collection-header .header-name-padding {
    padding-top: 382px;
}

#collection-header .header-square-col{
    margin-top: 336px;
}

#collection-header .profile-pic img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 1px solid #dedede;
    box-sizing: border-box;
    
}

#collection-header .profile-pic {
    background-color: var(--background);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: relative;
}

#collection-header {
    word-wrap:break-word !important
}   



/* @media only screen and (max-width: 768px) {

  } */