.latest-profile-card {
    width: 95%;
    /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
    border-radius: 8px;
    border: none;
    position: relative;
    top: 0;
    transition: top ease 0.2s;

}

.latest-profile-card:hover {
    top: -5px;
    /* -webkit-filter: brightness(120%); */
    /* opacity: 0.8;
    transform: scale(1.01); */
    cursor: pointer;
}

#latest-profiles-card h3{
    font-size: calc(1rem + 0.45vw) !important;
}

#latest-profiles-card h4{
    font-size: calc(0.65rem + 0.35vw) !important;
}

@media only screen and (max-width: 576px) {
    .latest-profile-card {
        width: 95%;
        font-size: 10px;
        /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
        border-radius: 8px;
    }
}

.latest-profile-card a{
    text-decoration: none;
}

#card-img {
    padding: 0.5rem;
    border-radius: 50% ;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}

.profile-nft-selected {
    border-color: black;
    filter: none !important;
}

.latest-profile-card-link {
    text-decoration: none;
}

.profile-card-text {
    color: var(--text-secondary);
}

.textRow {
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;

}

