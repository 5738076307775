#thought-box-outer{
  width: 100%;
}

#live-feed-thought-box-wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  background-color: var(--background);
  width: 100%;
  padding: 1rem;
  // padding-top: 2rem;
}

#thought-box-outer .no-border{
  border: none !important;
}

#live-feed-thought-box-wrapper-mobile {
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  min-height: 114px;
  background-color: var(--background);
  padding: 1rem;
}
#thought-box-disclaimer {
}

#live-feed-wrapper #live-feed-thought-box-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#live-feed-thought-box-inner-row {
  display: flex;
  align-items: center;
  width: 100%;
}

#thought-input-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#live-feed-thought-box-wrapper #lf-thought-img-box {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  background-color: var(--background-profile);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;
}

#lf-thought-img-box #verify-img {
  position: absolute;
  bottom: 0;
  right: 0.05rem;
  width: 1rem;
}

#live-feed-thought-box-wrapper .lf-thought-icon-box,
#live-feed-thought-box-wrapper-mobile .lf-thought-icon-box {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

#live-feed-thought-box-wrapper textarea,
#live-feed-thought-box-wrapper textarea:focus,
#live-feed-thought-box-wrapper textarea:focus-visible {
  margin-right: auto;
  border: none;
  background-color: var(--background);
  outline: none;
  color: var(--text-primary);
  font-weight: 400;
  width: 100%;
  resize: none;
}

#live-feed-thought-box-wrapper-mobile textarea,
#live-feed-thought-box-wrapper-mobile textarea:focus,
#live-feed-thought-box-wrapper-mobile textarea:focus-visible {
  margin-right: auto;
  border: none;
  background-color: var(--background);
  outline: none;
  color: var(--text-primary);
  font-weight: 400;
  width: 100%;
  resize: none;
}

#live-feed-thought-box-wrapper #lf-thought-text-box {
  display: flex;
  width: 100%;
  background-color: var(--background);
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  padding: 1rem;

  /* display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-profile);
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    margin-right: 1rem; */
}
#lf-thought-header-box {
  display: flex;
  width: 100%;
  background-color: var(--background);
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  padding: 0.4rem 1rem !important;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

#live-feed-thought-box-wrapper #livefeed-thought-tags-box {
  margin-top: 0.5rem;
  border-radius: 8px !important;
  width: 100%;
}

#live-feed-thought-box-wrapper #livefeed-thought-tags-box-inner {
  padding: 0.4rem 1rem !important;
}

#live-feed-thought-box-wrapper #lf-thought-icon-row {
  display: flex;
  align-items: center;
}

#live-feed-event-item-thought-reactions-row #reaction-icon-row {
  display: flex;
  align-items: center;
  position: absolute;
  margin-left: 1.8rem;
  background: var(--background);
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  padding: 0.6rem 1.2rem;
  z-index: 25;
}

#live-feed-event-item-thought-reactions-row #reaction-icon-row:focus {
  border: 1px solid #d0d5dd !important;
  box-shadow: none !important;
  outline: none !important;
}

#live-feed-thought-box-wrapper #lf-thought-icon-row .EmojiPickerReact {
  position: absolute;
  z-index: 12;
}

#live-feed-thought-box-wrapper-mobile #lf-thought-icon-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#live-feed-thought-box-wrapper #lf-thought-icon-row-mobile {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  #live-feed-thought-box-wrapper {
    width: 100%;
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
  #lf-thought-header-box,
  #lf-thought-header-box:focus,
  #lf-thought-header-box:focus-visible {
    display: flex;
    width: 100%;
    background-color: var(--background);
    border: none !important;
    border-radius: 8px;
    padding: 0.5rem 0rem !important;
    margin-bottom: 2rem;
    outline: none;
  }
  #livefeed-thought-tags-box {
    margin-top: 1rem;
    width: 100%;
    .tags-input-container {
      border: none !important;
      .tags-input {
        padding: 1rem 0.4rem !important;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  // #live-feed-thought-box-wrapper #lf-thought-icon-row .EmojiPickerReact {
  //   position: static;
  // }
}
