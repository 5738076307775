.create-album-card-selected {
    border-color: black !important;
    border-radius: 10px;
    filter: none !important;
}
.form-check-album {
    border-radius: 10px;
    border: 1px solid transparent
}

.noBorder {
    border: none;
}

#create-album-modal .secondaryText {
    color: var(--text-secondary);
    width: 80%;
}

.createButton {
    width: 100%;
}