#live-feed-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: calc(100vh - 86px);
  background-color: var(--background-profile);
  width: 92%;
  .row {
    width: 100%;
  }

  #live-feed-header-wrapper {
    padding: 0rem;
    #lf-back-wrapper {
      color: var(--text-secondary);
      cursor: pointer;
    }

    #lf-back-span {
      display: flex;
      align-items: center;
    }

    #lf-title-span {
      font-size: 2rem;
      font-weight: 600;
    }

    #lf-sub-title-span {
      font-weight: 400;
      font-size: 1rem;
      color: var(--text-secondary);
    }
  }
  #live-feed-tabs-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem !important;
    .tab-item {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: 1rem;
      cursor: pointer;
      padding: 1rem;
      border-radius: 6px;
      color: var(--text-secondary);
      font-weight: 600;
    }

    .tab-item-active {
      background-color: #def1e9;
      color: #429775;
    }
    #powered-by-span {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: 400;
      color: var(--text-secondary);
    }

    #powered-by-span img {
      width: 2rem;
      height: 2rem;
    }

    #tabs-row {
      display: flex;
      align-items: center;
    }

    #tabs-row-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      select {
        display: flex;
        align-items: center;
        justify-content: flex-start !important;
        width: 100%;
        border-radius: 8px !important;
        border: 1px solid #d0d5dd !important;
        padding: 0 !important;
        text-align: left;
        font-weight: 600;
        padding-left: 0.5rem !important;
        color: #667085 !important;
        outline: none !important;
      }

      option {
        text-align: left;
        font-weight: 600;
        color: #667085 !important;
      }
    }
  }
}

#live-feed-wrapper #live-feed-data-box-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

#live-feed-data-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  width: 88%;
  border: 1px solid #d0d5dd !important;
  border-radius: 12px;
  a {
    text-decoration: none;
  }
}

#live-feed-data-box-loading-wrapper {
  width: 100%;
  display: flex;
  text-align: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 500;
  color: var(--text-secondary);
}

#live-feed-event-item-wrapper {
  width: 100%;
  border-bottom: 1px solid #d0d5dd;
  #live-feed-event-item-row {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;

    #lf-item-icon-box {
      display: flex;
      align-self: flex-start;
      align-items: center;
      justify-content: center;
      background-color: var(--background-profile);
      width: 50px !important;
      height: 50px !important;
      border-radius: 50%;
      margin-right: 1rem;
      position: relative;

      #verify-img {
        position: absolute;
        bottom: 0;
        right: 0.05rem;
        width: 1rem;
      }
    }
    #lf-item-info-box {
      margin-right: auto;
      #live-feed-event-item-thought-profile-name-row {
        font-weight: 600;
      }
      #live-feed-event-item-thought-find-name-row {
        font-size: 0.7rem;
        font-weight: 600;
      }
      #live-feed-event-item-thought-header {
        font-weight: 600;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      #live-feed-event-item-thought-content {
        color: var(--text-secondary);
        font-size: 0.95rem;
      }
      #lf-item-name-span {
        font-weight: 600;
      }

      #lf-item-from-to-span {
        font-weight: 400;
        font-size: 0.8rem;
        color: var(--text-secondary);
      }
      #live-feed-event-item-thought-tag-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        margin-top: 1rem;
        .tag-item {
          margin-left: 0 !important;
          margin-right: 0.3rem;
          margin-top: 0.3rem;
          background-color: var(--background);
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: 8px !important;
          border: 1px solid #d0d5dd !important;
          padding: 0.1rem 0.4rem;
          font-size: 0.65rem;
          color: var(--text-secondary);
        }
      }
      #live-feed-event-item-thought-reactions-row {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        position: relative;
        #thought-reaction-toggle {
          cursor: pointer;
        }

        #reacted-tooltip {
          display: flex;
          flex-direction: column;
        }

        #thought-reaction-box {
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
          cursor: pointer;
          border-radius: 8px !important;
          border: 1px solid #d0d5dd !important;
          padding: 0.1rem 0.4rem;
          font-size: 0.65rem;
        }
      }
    }
    #lf-item-end-box {
      align-self: flex-start;
      #live-feed-event-item-price-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: flex-start;
        margin-bottom: 0.5rem;
        #lf-item-price-box {
          font-weight: 600;
        }

        #lf-item-kebab-box {
          cursor: pointer;
          display: flex;
          margin-left: 1rem;
          align-items: center;
          img {
            margin-bottom: 5px;
          }
        }
        #lf-item-price-box {
          font-weight: 600;
        }
      }
      #lf-item-time-box {
        font-size: 0.72rem;
        font-weight: 400;
        color: var(--text-secondary);
        text-align: end;
      }
    }
  }
}

#live-feed-event-item-wrapper:last-child {
  border-bottom: none !important;
}

/* .lf-background {
  background-image: url(./findbg.png);
  background-size: cover;
  height: 550px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
} */

/* .lfDataBox {
  z-index: 3;
} */

@media only screen and (max-width: 768px) {
  #live-feed-wrapper {
    width: 100%;
  }

  #live-feed-data-box-wrapper {
    width: 100%;
    border-radius: 0;
    border-left: none !important;
    border-right: none !important;
    /* padding-left: 1rem;
    padding-right: 1rem; */
  }

  #live-feed-tabs-wrapper #powered-by-wrapper {
    /* display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start; */
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  #live-feed-header-wrapper,
  #live-feed-wrapper #live-feed-thought-box-row,
  #live-feed-event-item-wrapper,
  #live-feed-header-wrapper,
  #tabs-row-mobile {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  #lf-thought-icon-row {
  }

  #powered-by-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }

  #live-feed-data-box-loading-wrapper {
    padding-left: 1rem;
  }
}

#thought-box-outer-outer {
  width: 88%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media only screen and (max-width: 768px) {
  #thought-box-outer-outer {
    width: 100% !important;
    margin-bottom: 1rem;
    // padding-left: 0 !important;
    //   padding-right: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  #lf-tabs-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #powered-by-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #live-feed-event-item-thought-reactions-row #reaction-icon-row {
    flex-direction: column;
    /* padding: 1rem; */
    margin-left: 2rem;
  }

  #live-feed-event-item-thought-reactions-row #thought-reaction-box {
    margin-top: 0.5rem;
  }
}
