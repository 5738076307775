#footer-links-wrapper {
  /* height: 20vh; */
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}

.footer-link {
  text-decoration: none;
  color: var(--footer-link);
  font-size: 1rem;
  font-weight: 450;
}

.footer-link:hover {
  color: var(--theme-main);
}
