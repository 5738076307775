.graffleLink {
    text-decoration: none;
}
.tableRow{
    width: 100%;
}

.secondaryText {
    color: var(--text-secondary);
}

#profile-latest-transactions {
overflow: auto;
}

/* .titleRow {
} */

#profile-latest-transactions-table{
    table-layout: fixed;
    width: 100%;
}

#profile-latest-transactions-table .tableContent{
    display: block;
    max-height: 55vh;
    overflow: auto;
    width: 100%;
}

#profile-latest-transactions-table a{
    text-decoration: none;
}

/* #profile-latest-transactions-table .tableRow{

} */

#profile-latest-transactions-table .tableRow1{
height: 60px;
}

#profile-latest-transactions-table .tableRow2{
height: 40px !important;
}

#profile-latest-transactions-table .iconCell{
    text-align: center;

}

/* #profile-latest-transactions-table .eventTypeCell{

} */

#profile-latest-transactions-table .nameCell{
width: 60%;
}

#profile-latest-transactions-table .amountCell{
width: 15%;
text-align: center;
font-weight: bold;
}

#profile-latest-transactions-table .mobileAmountCell{
    font-weight: bold;
    text-align: end;
    }

/* #profile-latest-transactions-table .timeCell{

} */

#profile-latest-transactions-table .mobileTimeCell{
text-align: start;
}

#profile-latest-transactions-table .messageCell{
    font-style: italic;
    color: var(--text-secondary);

}


#profile-latest-transactions-table .center-cell {
    vertical-align: middle;
}

#profile-latest-transactions-table .no-border {
    border: none;
}

#profile-latest-transactions-table .main-text {
    color: var(--text-primary);
}

#profile-latest-transactions-mobile-row{
    height: 60px !important;
}


.graffleLogoRow{
min-height: 64px;
}

.activityLink {
    text-decoration: none;
    cursor: pointer;
}

.nameCell{
color: var(--text-primary);}

@media only screen and (max-width: 768px) {
    .nameCell{
        text-align: end;
    }
    
  }