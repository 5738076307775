.popup-progress-overlay{
	position: absolute;
	top: 0;
	left:0;
	right: 0;
	bottom: 0;
	background-color: white;
	padding: 0;
	border-radius: 10px;
	min-height: 8rem;

	p{
		user-select: none;
	}
}
hr{
	color: #aaa;
}