.discordBG {
    background-image: url('./Discord-banner.webp');
    background-size: cover;
    height: 200px;
}
.discordTitle {
    font-weight: 900;
    font-size: calc(1.75rem + 1.5vw);
    color: black;
}

.discordText {
    max-width: 100%;
}