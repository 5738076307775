
    #names-for-sale-accordion img {
        margin-right: 5px;
    }
    #names-for-sale-accordion .card, .card-header {
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding-left: 0px;
        padding-right: 0px;
    }