
.breadcrumbs {
color: var(--text-secondary);
}

.breadcrumbs a{
text-decoration: none;
color: var(--text-secondary)

}

.breadcrumbs a:hover{
    text-decoration: underline;
    cursor: pointer;
    }