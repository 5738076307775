.doodle {
  --bezier: cubic-bezier(0.73, 0, 0.46, 1.3);
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 100px auto auto;
  transition: transform var(--bezier) 0.5s, filter ease-in-out 1s;
  /*    filter: saturate(var(--saturation))} opacity(var(--opacity));*/
  transform: translate3d(0, 0, 0);
  max-width: 80%;
  justify-self: center;

  &__preview-container {
    position: relative;

    &__preview {
      display: block;
      width: 80%;
      overflow: hidden;
      aspect-ratio: 1;
      border-radius: 10px;
      margin: 0 auto;
      box-shadow: -1px 2px 0 1px #cee3eb;
      transform: translate3d(-5px, 30px, 0) rotate(-7deg);
      transition: all var(--bezier) 0.5s;
      position: relative;
      z-index: 2;
      background-color: white;

      &:after {
        content: "";
        display: block;
        z-index: 8;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 0 6px white;
      }
    }

    &__crop {
      display: block;
      position: absolute;
      --pad: 5px;
      top: var(--pad);
      left: var(--pad);
      right: var(--pad);
      bottom: var(--pad);
      border-radius: 10px;
      overflow: hidden;
      background-color: #cceef8;
    }
  }

  &__image {
    width: 100%;
    z-index: 1;
    position: relative;
    transition: all var(--bezier) 0.5s;
  }

  &__shadow-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 150px;
    border-radius: 12px 12px 0 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    perspective: 600px;
    pointer-events: none;

    &:after {
      content: "";
      display: block;
      width: 90%;
      aspect-ratio: 1;
      border-radius: 14px;
      position: absolute;
      background-color: rgb(132 200 227 / 15%);
      transition: all var(--bezier) 0.5s;
      transform: translate3d(10px, -75%, 0) rotate3d(-1, 1, 0, -25deg);
    }
  }

  &__details-container {
    background-color: #cdeef9;
    padding: 50px 16px 0;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      font-family: Chalkboard !important;
      font-size: 22px;
      margin: 0;
      color: #086489;
      display: flex;
      align-items: flex-end;
    }

    a {
      text-decoration: none;
    }

    h2,
    p {
      color: #397c97;
      margin: 0;
    }
  }

  &__price-container {
    background-color: #cdeef9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9090e8;
    padding: 0.5em 16px;

    &--dim {
      font-weight: normal;
      color: #a6a6f0;
    }
  }

  &__action-container {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #bceafc;
    border-radius: 0 0 16px 16px;
    box-shadow: 0 3px #9ad8ef, inset 0 1px rgb(172 221 239);
  }

  &__button {
    display: flex;
    font-size: 20px;
    width: 100%;
    color: white;
    border: none;
    padding: 2px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #a4a4f4;
    cursor: pointer;
    box-shadow: 0 3px #8e8eed;

    &__nobg {
      display: flex;
      font-size: 20px;
      width: 100%;
      color: white;
      border: none;
      padding: 2px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__price {
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
    align-items: center;
    gap: 5px;
    color: #086489;
  }

  // Hover styles
  &:hover {
    filter: saturate(1) opacity(1);
    transition: all var(--bezier) 0.17s;
    transform: translate3d(0, -3px, 0);

    .doodle {
      &__image {
        --boom: 1.35;
        transform: scale3d(var(--boom), var(--boom), var(--boom)) rotate3d(0, 0, 1, 5deg);
        transition: all var(--bezier) 0.32s;
      }

      &__preview-container__preview {
        --boom: 1.15;
        transform: translate3d(0, 30px, 0) scale3d(var(--boom), var(--boom), var(--boom));
        box-shadow: 0 3px 0 1px #cee3eb;
        transition: all var(--bezier) 0.17s;
      }

      &__shadow-container:after {
        transform: translate3d(0, -150px, 0) rotate3d(0, 0, 1, 0deg) rotate3d(1, 0, 0, 0deg);
        transition: all var(--bezier) 0.17s;
      }
    }
  }
}