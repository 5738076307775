#list-name .form-check-input {
  background-color: var(--background) !important;
  height: 32px !important;
  width: 32px !important;
  margin-right: 10px;
}

#list-name .form-check-input:checked {
  background-color: var(--theme-main) !important;
  border-color: var(--background);
}

#list-name .form-check-label {
  margin-top: 9px;
}

.modal-content {
  background-color: var(--background);
  color: var(--text-primary);
  border-radius: 10px;
}

input.custom-input-list-name,
input.custom-input-list-name:focus{
  border: 1px solid #ccc !important;
}

