.dp {
  &__increment {
    display: flex;
    gap: 1rem;
    font-family: Roboto, sans-serif;
    align-items: center;

    span {
      width: 1em;
      text-align: center;
      font-size: 14px;
      color: black;
      user-select: none;
    }

    button {
      --size: 36px;
      width: var(--size);
      height: var(--size);
      border: 2px solid #f6f6f6;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      cursor: pointer;
      user-select: none;

      img{
        margin: 0;
      }

      &:hover {
        border: 2px solid #58b792;
      }
      &:active {
        background-color: #f6f6f6;
      }
    }
  }
}
[data-theme="dark"] {
  .dp {
    &__increment {
      button {
        background-color: #95a09c;
        border-color: #95a09c;

        &:hover {
          border: 2px solid #b8c9c3;
        }
        &:active {
          background-color: #b8c9c3;
        }
      }

      span{
        color: #e3eae8;
      }
    }
  }
}
