.image-div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.caption{
    color: var(--text-secondary);
    font-size: 12px;
    padding-top: 5px;
}

#help-hub-info section{
    scroll-margin-top: 90px;

}