@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

.dp {
  &__project {
    &-name {
      font-weight: 500;
      font-size: 12px;
    }
    &-title {
      font-family: Roboto, sans-serif;
    }
  }
}

[data-theme='dark'] {
  .dp {
    &__project{
      &-name{
        color: #e3eae8;
      }
    }
  }
}
