#locked-name-wrapper {
  a {
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  text-align: center;
  #locked-name-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -5vw;
    #locked-name-title {
      font-size: 2.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
    #locked-name-desc {
      color: var(--text-secondary);
      #owner-link {
        color: var(--theme-main);
        font-weight: 600;
      }
      #date-span {
        font-weight: 600;
      }
    }
  }
  #owned-name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    color: var(--text-secondary);
    #owned-name-title {
      font-size: 1.2rem;
      font-weight: 600;
    }
    #owned-name-renew-until {
      #date-span {
        font-weight: 600;
      }
    }
    #owned-name-cost {
      font-weight: 600;
    }
    #owned-name-button-row {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
