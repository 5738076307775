#live-feed-data-box{
    box-shadow: var(--shadow);
    border: none !important;
    border-radius: 10px;
    background-color: var(--background);
    z-index: 0;
    position: relative;
}

#live-feed-data-box a{
   text-decoration: none;
}

.main-text {
    color: var(--text-primary);
}

.no-border {
    border: none;
}
.row2Border {
    border-bottom: 1px solid #E3E3E3;

}

.center-cell {
    vertical-align: middle;
    
}

.align-cell {
    text-align: center; 

}

.row2Text {
    font-size: 12px;
    font-weight: 400;
    color: var(--text-secondary)
}

.livefeed-date{

    font-size: 12px;
font-weight: 400;
color: var(--text-secondary)
}

.graffleLink {
    text-decoration: none;

}

.width30 {
    width: 30px;
    height: 30px;
    border-radius: 8px;
}

