.settings-input {
border-radius: 12px;
background: var(--background);
color: var(--text-primary);

}

.settings-input:focus{
    background: var(--background);
    color: var(--text-primary);
    box-shadow: none;
    border: 1px solid #ced4da;
}

.settings-input:disabled{
    background: var(--background-profile);
    color: var(--text-primary);
    color: var(--text-secondary);


}


@media only screen and (max-width: 1400px) {
    .profile-nft-pfp {
        width: 216px;   
    }
}

@media only screen and (max-width: 576px) {
    .profile-nft-pfp {
        width: 155px;
        font-size: 10px;
        /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
        border-radius: 8px;
        margin-left: 0.75rem;
        margin-right: 0.75rem;

    }
}
#profile-tabs .profiletabs.nav {
    flex-wrap: inherit; 
}

#settings-container  .form-select {
    background-color: var(--background) !important;
    border-radius: 12px !important;
    border: 1px solid #ced4da !important;
}