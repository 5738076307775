@media screen and (max-width: 640px) {
  .header {
    &__container {
      grid-template-rows: 20vw auto !important;
    }
    &__content {
      padding: 0 1rem;
      grid-template-rows: 50px auto auto !important;
      grid-template-areas: "avatar avatar" "text text" "stats stats" !important;
    }

    &__avatar {
      position: absolute;
      padding: 0 20px !important;

      img {
        --size: 5rem !important;
        --half-size: -30px !important;
        

      }
    }

    &__description {
      display: none;
    }
  }
}

@media screen and (max-width: 640px) {
  .header {
    &__container {
      grid-template-rows: 15vh auto !important;
    }

    &__content {
      padding-bottom: 0 !important;
    }

    &__stats {
      display: none;
    }
  }
}
