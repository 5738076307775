.breadcrumbs {
  &-container {
    color: var(--text-secondary);
  }

  &-item{
    &::before{
      content: ">";
    }
  }

  &-link {
    color: var(--text-secondary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &::after{
      content: ">";
      margin: 0.25em;
      display: inline-block;
      text-decoration: none;
    }

    &:last-child {
      margin-right: 0;

      &::after{
        content: "";
      }
    }
  }
}
