#navhead-banner-wrapper {
  font-family: "Baloo Paaji 2";
  font-weight: 600;

  color: #5c5c5c;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 86px;
  width: 100%;
  background: rgba(166, 255, 146, 1);
  z-index: 1111;
  padding: 0.4rem 1rem;
  #banner-close {
    margin-left: auto;
    cursor: pointer;
  }
}

@media only screen and (max-width: 992px) {
  #banner-text {
    margin-left: 0 !important;
    width: 80%;
  }
  
}
