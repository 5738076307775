#create-profile-container {
  background-color: var(--background-profile);
  height: calc(100vh - 86px);
  min-height: 600px;
}

#create-profile-wrapper {
  height: calc(100vh - 86px);
  min-height: 600px;

  /* height: calc(100vh - 86px);
    min-height: 450px;
    position: relative; */
}

#create-profile-box {
  background-color: var(--background);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 1rem;
  width: 500px !important;
}
.create-wrapper {
  margin-top: 30px;
  margin-bottom: 30vh;
  width: 100%;
}

/* left col */
#create-profile-left-col {
  position: relative;
  height: calc(100vh - 86px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-create-stages);
}

#create-profile-stages-wrapper {
  position: absolute;
  top: calc(28vh - 86px);
  display: flex;
  flex-direction: column;
}

#create-profile-stages-wrapper .stage-text-col {
  margin-top: 4px;
  margin-left: 8px;
}

#create-profile-stages-wrapper .stage-title-span {
  color: var(--text-primary);
  font-size: 0.95rem;
  font-weight: 450;
}

#create-profile-stages-wrapper .stage-title-span-active {
  color: var(--theme-main);
  font-size: 0.95rem;
  font-weight: 450;
}

#create-profile-stages-wrapper .stage-title-span-completed {
  color: var(--theme-main);
  font-size: 0.95rem;
  font-weight: 450;
  opacity: 0.77;
}

#create-profile-stages-wrapper .stage-desc-span {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

#create-profile-stages-wrapper .stage-desc-span-completed {
  color: var(--text-secondary);
  font-size: 0.9rem;
  opacity: 0.84;
}

/* //right col */
#create-profile-right-col {
  position: relative;
  height: calc(100vh - 86px);
  min-height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#create-profile-right-col .create-profile-continue-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  cursor: pointer;
}

#create-profile-right-col .create-profile-explore-card {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  cursor: pointer;
}

#create-profile-right-col .explore-link {
  text-decoration: none;
}

#create-profile-right-col .create-card-text-wrapper {
  padding-left: 1rem;
}

#create-profile-right-col .create-card-upper-text {
  font-weight: 500;
}

#create-profile-right-col .create-card-lower-text {
  font-size: 0.88rem;
  color: var(--text-secondary);
}

#create-profile-form-wrapper {
  position: absolute;
  top: calc(20vh - 86px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

#create-profile-wrapper .row {
  width: 100%;
  --bs-gutter-x: 0rem;
}

#create-profile-wrapper #create-profile-form-header {
  font-size: 2.5rem;
  font-weight: 600;
}

#create-profile-wrapper #create-profile-form-desc {
  color: var(--text-secondary);
}

#create-profile-wrapper #create-profile-form-info {
  color: var(--theme-main);
  font-size: 0.95rem;
  font-weight: 450;
  cursor: pointer;
}

#create-profile-form-info-clause {
  color: var(--text-primary);
  font-size: 1.2rem;
}

#info-button-row{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  width: 100% !important;

  button{
    width: 100% !important;
  }
}

#create-profile-input-wrapper {
}

#create-profile-input-wrapper input,
#create-profile-input-wrapper input:focus,
#create-profile-input-wrapper input:focus-within {
  width: 100%;
  height: 44px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: none !important;
}

#create-profile-input-wrapper .invalid {
  border: 1px solid red !important;
}

#create-profile-input-wrapper .valid {
  border: 1px solid var(--theme-main) !important;
}

#create-profile-input-wrapper #create-profile-input-button,
#create-profile-input-wrapper #create-profile-input-button:focus,
#create-profile-input-wrapper #create-profile-input-button:focus-within {
  width: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  gap: 8px;
  height: 44px;
  border-radius: 68px;
  border: 1px solid black;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.96rem;
}

#create-profile-input-wrapper #validation-error-wrapper {
  text-align: end;
}

#create-profile-input-wrapper .validation-error-invalid {
  color: red;
  font-size: 0.8rem;
}

#create-profile-input-wrapper .validation-error-valid {
  color: var(--theme-main);
  font-size: 0.8rem;
}

#create-profile-input-wrapper #find-name-status-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
}

#create-profile-input-wrapper #find-name-status-status {
}

#create-profile-input-wrapper #find-name-status-name {
  font-weight: 500;
  margin-left: 0.5rem;
}

#create-profile-stage-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 24px;
  flex-direction: row;
}

#create-profile-stage-bar-wrapper #stage-bar-row {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#stage-bar-row .stage-icon-wrapper {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

#stage-bar-row .stage-icon-completed {
  opacity: 0.44;
}

@media only screen and (max-width: 768px) {
  #create-profile-right-col {
    position: relative;
    height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  #create-profile-form-wrapper {
    position: absolute;
    top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84%;
  }

  #create-profile-wrapper #create-profile-form-header {
    font-size: 1.8rem;
    font-weight: 600;
  }

  #create-profile-wrapper #create-profile-form-desc {
    font-size: 0.92rem;
    color: var(--text-secondary);
  }
  #create-profile-stage-bar-wrapper {
    width: 84%;
  }

  #stage-bar-row .stage-icon-wrapper {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }
}

@media screen and ( max-height: 800px ) { 
  #create-form-divider {
      display: none !important;
  }
}