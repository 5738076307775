.propertiesRoyalty {
    /* font-size: small;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px; */
    background-color: var(--background-tertiary);
    border-radius: 10px;
    border: 1px solid var(--theme-main);
}

.propertiesRoyaltyCollection{
  background-color: var(--background);
    border-radius: 10px;
    border: 1px solid var(--theme-main);
}

.propertiesTraits{
text-align: center;

}

#nft-detail-details-card{
  background-color: var(--background-navigation);
  color: var(--text-primary);
}

.traitsWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    word-break: break-word;
    border-radius: 10px;
    background-color: rgba(88, 183, 146, 0.1) !important;
    border: 1px solid var(--theme-main);
    min-height: 130px !important;

}

.traitsWrap:hover{
  opacity: 0.73 !important;

}

.royaltyWrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}


.propertiesWrap {
    word-break: break-word;
    color: var(--text-secondary);
    font-size: small;
}

.propertiesWrap a {
    text-decoration: none;
    color: var(--text-secondary)

}

.propertiesWrap a:hover {
    color: var(--theme-main)

}

#nft-detail-details-card h3{
margin-bottom: 0 !important;
text-align: end;
}

@media only screen and (max-width: 991px) {
    #nft-detail-details-card h3{    
        text-align: start;
    }
  }
  .propRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  

  .royaltyRow{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  

 

  @media only screen and (max-width: 992px) {
    .propRow{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .royaltyRow{
        display: flex;
        align-items: center;
        justify-content: center;
      }

  }

