#marketplacepage .input, #marketplacepage select{
    background-color: var(--background) !important;
    height: 40px !important;
    border-radius: 10px !important;
    border: none !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}
#marketplacecollectionpage .input:focus, #marketplacepage .input:focus-visible, #marketplacepage select:focus{
    background-color: var(--background) !important;
    border: none !important;
    box-sizing: border-box;
    outline: none;
    height: 40px !important;
    border-radius: 10px !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}



#marketplacepage .form-check{

}

.mpSortBy{
    border: none !important;
    height: 40px !important;
    border-radius: 10px !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: small !important;
    letter-spacing: 0.2px;
    background-color: var(--background) !important;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}
.mpSortBy:focus{
    background-color: var(--background) !important;
    border: none !important;
    box-sizing: border-box;
    outline: none;
    height: 40px !important;
    border-radius: 10px !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: small !important;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}

#marketplacepage .accordion-item {
    background-color: transparent;
    border: none;
}
#marketplacepage .accordion-button:not(.collapsed) {
    box-shadow: none;
    color: #58B792;
    text-decoration: none !important;
}

#marketplacepage .accordion-button::after {
    background-image: var(--accordion-chevron)
}

#marketplacepage .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2358B792'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}

#marketplacepage .accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

#marketplacepage #algolia_search{
    padding-left: 10px;
}

#marketplacepage #algolia_search:focus-visible{
  outline: none !important;
}

#marketplacecollectionpage .accordion-item {
    background-color: transparent;
    border: none;
}
#marketplacecollectionpage .accordion-button:not(.collapsed) {
    box-shadow: none;
}
#marketplacecollectionpage .accordion-button:focus {
    border: none !important;
    box-shadow: none !important;
}

 .cardCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mpRefinementDropdownToggle{
    /* height: 40px !important;
    border-radius: 40PX !important;
    border: 1px solid #ced4da !important;
    background-color: var(--background) !important;
    background-size: cover;
    padding: 0.375rem 1rem 0.375rem 0.75rem; */
    /* width: 100% !important; */
    text-decoration: none;
}

.mpRefinementDropdownToggle a{
      text-decoration: none;
}

.mpRefinementDropdownMenu {
    background-color: var(--background) !important;
    color: var(--text-primary);
}
/* .mpRefinementDropdown {
    width: 100% !important;
} */
/* .mpRefinementDropdownToggle.dropdown::after {
    background-image: var(--accordion-chevron) !important;
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto !important;
    content: "hel";
    background-position: right 0px;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
} */

.dropdown-menu {
    background-color: var(--background) !important;
    color: var(--text-primary);
}

.dropdown-item:hover {
    color: var(--text-primary);
}

.dropdown-menu a{
    background-color: var(--background) !important;
    color: var(--text-primary);
}

.dropdown-menu input {
    background-color: var(--background) !important;
    color: var(--text-primary);
}

.profileMenuPic img {
    height: 48px !important;
    width: 48px !important;
    border-radius: 50% !important;
    /* box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.15); */
    transition: 0.5s !important;
    border: 1px solid #D3D3D3 !important;
    box-sizing: border-box;
}
.refinementsList {
    display: flex;
    color: var(--text-primary);
    font-size: 12px;
    background-color: var(--background);
    margin-left: 5px; 
    border: 2px solid;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.refinementsListMobile {
    display: flex;
    color: var(--text-primary);
    font-size: 12px;
    background-color: var(--background-profile);
    margin-left: 5px; 
    border: 2px solid;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;

}

.refinementsButton{
    padding-left: 10px;
    color: var(--text-secondary);
    background: var(--mp-filter-close);
}

.refinementsButtonClear{
    padding-left: 0;
    color: var(--text-secondary);
    background: var(--mp-filter-close);
}


.ais-SortBy-select{
    border: none !important;
    background-image: var(--select-chevron);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;

    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.ais-SortBy-select:focus{
    border: none !important;
    background-image: var(--select-chevron);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.refinementsSelect{
    border-radius: 10px !important;
    border: 1px solid #ced4da !important;
    background-color: var(--background) !important;
    color: var(--text-primary);
}

.market-detail {
    width:100% !important;
    /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
    border-radius: 8px;
    background-color: var(--background-navigation);
    border: 1px solid transparent !important;
}

#detail-tabs .profiletabs.nav {
    flex-wrap: inherit; 
}
.market-detail img {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    /* aspect-ratio: 1/1 !important; */
}
.market-detail video {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}
.market-detail-nft-selected {
    border-color: black;
    filter: none !important;
}
.market-text-nft-detail {
    white-space: pre;
    text-align: center;
    height: auto;
}
.market-text-nft-wrapper-detail {
    min-height: 300px;
    display: flex;
}
#market-detail-acc.accordion .accordion-item,.accordion-header,.accordion-button {
    background-color: transparent !important;
    color: var(--text-primary);
    border: none;
    padding-left: 0;
    padding-right: 0;
}
#market-detail-acc .accordion-button::after{
    background-image: var(--accordion-chevron);
}
#market-detail-acc .accordion-button:focus{
    border-color: transparent;
    box-shadow: none;
}
#market-detail-acc .accordion-button:not(.collapsed){
    color: var(--theme-main);
    box-shadow: none !important;
}

#mp-detail-lightbox{
display: flex;
align-items: center;
justify-content: center;
}

.ril-image-current {
    max-height: 80vh !important;
}

.btn-close:focus {
    outline: 0;
    box-shadow: none;
    opacity: 1;
}

.mp-price-box-card{
border: 1px solid #D7D7D7 !important;
background-color: var(--background-navigation);
    color: var(--text-primary);
}


#mp-price-box-card-sale .mp-price-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    }

.mp-price-row h3{
    margin-right: 5px;
}

@media only screen and (max-width: 576px) {
    .market-detail {
        /* width: 155px; */
        border-radius: 8px;
    }
    .detail-container{
        width: 97%;

    }
    .ril-image-current {
        max-height: none !important;
    }

    #filters-col{
        visibility:hidden;
        width: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}


#mp-title-row{
    display: flex;
    align-items: center;
    position: sticky !important;
    top: 86px !important;
    /* overflow-y: auto;
    overflow-x: hidden; */
    z-index: 999;
    background-color: var(--background-profile);
}


.hidden-filters{
    visibility:hidden;
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    /* transition: all 1s ease !important; */
}

.fine-print{
    font-size: 0.8em;
    color: var(--text-secondary);
    margin: 0;
}

.fine-print:before{
    content: "*";
}