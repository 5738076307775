.info-cards-bg {
    background-image: url(./findbg.webp);
    background-size: cover;
    height: 350px;
    width: 100%;
    position: absolute;
    top: 0px;
}
.front-Cards .card{
    background: var(--background);
    border-radius: 10px;
    height: 100%;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  }
.title-txt {
    font-size: calc(1.20rem + 0.2vw);
    /* color: var(--text-primary) */
  }

  #info-cards {
    scroll-margin-top: 86px;
  }