#col-detail-acc.accordion .accordion-item,.accordion-header,.accordion-button {
    background-color: transparent !important;
    color: var(--text-primary);
    border: none;
    padding-left: 0px;
    padding-right: 0px;
}
#col-detail-acc .accordion-button::after{
    background-image: var(--accordion-chevron);
}
#col-detail-acc .accordion-button:focus{
    border-color: transparent;
    box-shadow: none;
}
#col-detail-acc .accordion-button:not(.collapsed){
    color: var(--theme-main);
    box-shadow: 0 !important;
}