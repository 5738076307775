.available-on-wrapper-find {
  border: 1px solid #d7d7d7 !important;
  background-color: var(--background);
  border-radius: 10px;
  .available-on-row {
    display: flex;
    align-items: center;
    .available-on-icon-col {
      position: relative;
      margin-right: 1rem;
      #icon-img {
        width: 3rem;
        border-radius: 50%;
      }
      #verify-img {
        position: absolute;
        bottom: 0;
        right: 0.05rem;
        width: 1rem;
      }
    }
    .available-on-info-col {
      margin-right: auto;
      .available-on-info-row-1 {
      }
      .available-on-info-row-2 {
        font-size: 0.8rem;
        color: var(--text-secondary);
      }
    }

    .available-on-price-col {
      .available-on-price-row-1 {
        display: flex;
        align-items: center;
      }
      .available-on-price-row-2 {
      }
    }
  }
}


.available-on-wrapper-flowty {
  border: 1px solid #d7d7d7 !important;
  background-color: var(--background);
  border-radius: 10px;
  .available-on-row {
    display: flex;
    // align-items: center;
    .available-on-icon-col {
      position: relative;
      margin-right: 1rem;
      #icon-img {
        width: 3rem;
        border-radius: 50%;
      }
      #verify-img {
        position: absolute;
        top: 2rem;
        right: 0.05rem;
        width: 1rem;
      }
    }
    .available-on-info-col {
      margin-right: auto;
      .available-on-info-row-1 {
      }
      .available-on-flowty-info-row{
        display: flex;

        .flowty-info-title{
          margin-right: auto;
        }
        .flowty-info-value{

        }
      }
    }

    .available-on-price-col {
      .available-on-price-row-1 {
        display: flex;
      }
      .available-on-price-row-2 {
      }
    }
  }
}

.available-on-wrapper-storefront {
  border: 1px solid #d7d7d7 !important;
  background-color: var(--background);
  border-radius: 10px;
  .available-on-row {
    display: flex;
    align-items: center;
    .available-on-icon-col {
      position: relative;
      margin-right: 1rem;
      #icon-img {
        width: 3rem;
        border-radius: 50%;
      }
      #verify-img {
        position: absolute;
        bottom: 0;
        right: 0.05rem;
        width: 1rem;
      }
    }
    .available-on-info-col {
      margin-right: auto;
      .available-on-info-row-1 {
      }
      .available-on-info-row-2 {
        font-size: 0.8rem;
        color: var(--text-secondary);
      }
    }

    .available-on-price-col {
      .available-on-price-row-1 {
        display: flex;
        align-items: center;
      }
      .available-on-price-row-2 {
      }
    }
  }
}
