
.pfp-card-active {
    box-shadow: rgb(88, 183, 146, 0.4) -5px 5px, rgb(88, 183, 146, 0.3) -10px 10px, rgb(88, 183, 146, 0.2) -15px 15px, rgb(88, 183, 146, 0.1) -20px 20px, rgb(88, 183, 146, 0.05) -25px 25px;
}
.pfp-card {
    border-radius: 10px;
    border: 10px solid transparent;
}


.profile-nft-pfp {
    width: 200px;
    /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
    border-radius: 8px;
    background-color: var(--background-profile);
    border: none;
    position: relative;
    top: 0;
    transition: top ease 0.5s;

}


.profile-nft-pfp:hover {
    /* -webkit-filter: brightness(110%); */
    transform: scale(1.01);
    cursor: pointer;

}

.profile-nft-pfp img {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}
.profile-nft-pfp video {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
}
.profile-nft-selected {
    border-color: black;
    filter: none !important;
}
.text-nft {
    white-space: pre;
    text-align: center;
    height: auto;
}
.text-nft-wrapper {
    height: 198px;
    display: flex;
}

.profile-nft-link {
    text-decoration: none;
}
