.filters-title {
display: flex;
align-items: center;
}

#mp-side-bar{
    position: sticky !important;
    top: 86px !important;
    max-height: calc(100vh - 112px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1rem !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#mp-side-bar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  #mp-side-bar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

#show-filter-label {
    width: 24px;
    height: 24px;
    background-image: var(--mp-filter);
    margin-right: 8px;
    cursor: pointer;
}

.floatClickedActive{
    padding: 4px;
    border-radius: 8px;
    border: 1px solid var(--theme-main) !important;
}

.floatClicked{
padding: 4px;
border-radius: 8px;
border: 1px solid transparent !important;
}

#mp-side-bar-float{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--background);
    height: 40px;
    border-radius: 10px;
}

#mp-side-bar-float-mobile{
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--background-profile) !important;
    height: 60px;
    border-radius: 10px;
}

.include-float-span{
    font-size: small;
}

#mp-side-bar-float .float-switch, #mp-side-bar-float-mobile .float-switch{
    margin-left: auto;
    
}

#mp-side-bar-float .float-switch-toggle, #mp-side-bar-float-mobile .float-switch-toggle{
    border: 1px solid var(--text-primary) !important;
}

.filterButton{
}