.collections-img {
    border-radius: 50%;
    margin: 10px;

}


.secondary-text {
    color: var(--text-secondary);
}

.best-collections-card {
    width: 85px;
    height: 85px;
    font-size: 0.75rem;
}