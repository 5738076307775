#fund-widget .accordion-item {
    border-radius: 10px !important;
    background-color: var(--background);
    box-shadow: var(--shadow);
    border: none !important;
}
#fund-widget .accordion-button.collapsed {
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-primary);
}
#fund-widget .accordion-button {
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-primary);
}
#fund-widget .accordion-button:focus {
    border: none;
    box-shadow: none;
}
#fund-widget .accordion-button::after {
    background-image: var(--accordion-chevron)
}
#fund-widget {
    /* max-width: 300px; */
}

#fund-widget .form-select {
    background-color: var(--background-profile) !important;
    border-radius: 10px !important;
}

#fund-widget .form-control {
    background-color: var(--background-profile) !important;
    border-radius: 10px !important;
}

#fund-widget .form-control::placeholder {
    font-size: smaller;
}