.header-card-bg {
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 102px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}
#profile-header .profile-pic img {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 1px solid #dedede;
    box-sizing: border-box;
    
}
#profile-header .profile-pic {
    background-color: var(--background);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: relative;
}
.header-name-padding {
    padding-top: 100px;
}

#profile-header {
    word-wrap:break-word !important
}   

#profile-header .tag-item{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary) !important;
    font-size: 15px;
    background-color: var(--background-profile);
    margin-left: 5px; 
    /* border: 2px, solid; */
    border-radius: 8px;
    padding-left: .3em;
    padding-right: .3em;
    border: 1px solid var(--text-secondary) !important;
}

.info-col{
    max-width: 80%;
}

@media only screen and (max-width: 768px) {
    .info-col{
        max-width: 100%;
    }
  }