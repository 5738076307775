.smallText {
  font-size: 11px;
  color: var(--text-secondary);
}

.price-span {
  word-break: break-all !important;
  width: 100%;
}

#valid-til {
  z-index: 100;
  position: absolute;
  color: black;
  font-size: small;
  /* font-weight:bold; */
  right: 10px;
  top: 10px;
  /* border: 1px solid grey; */
  background-color: var(--theme-main);
  border-radius: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  opacity: 0.8;
}

#valid-til img {
  height: 12px !important;
  width: 12px !important;
}

.salesTypeAuction {
  background-color: #99f0f5;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 0px;
  color: black;
}

.salesTypeSale {
  background-color: #e3fadf;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 0px;
  color: black;
}

.nftName {
  margin-bottom: 0px;
}

.mpCardCollectionRow {
  height: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(--b */
}

.mpCardNameRow {
  height: 2rem;
}

#fav-icon-box {
  /* background-color: var(--background) !important; */
}

@media only screen and (max-width: 576px) {
  .mpCardNameRow {
    height: 1.5rem;
  }

  .mpCardCollectionRow {
    height: 1.5rem;
  }
}

.market-nft {
  width: 95%;
  max-width: 300px !important;
  /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
  border-radius: 8px !important;
  border: none;
  top: 0;
  transition: all ease 0.2s;
  overflow: hidden !important;
}

.market-nft-more {
  top: 0;
  transition: top ease 0.2s;
  border-radius: 8px;
  background-color: var(--background) !important;
}

.market-nft-more {
  top: 0;
  transition: top ease 0.2s;
  border-radius: 8px;
  background-color: var(--background) !important;
  max-width: 300px !important;
}

.market-nft-more-detail {
  top: 0;
  transition: top ease 0.2s;
  border-radius: 8px;
  background-color: var(--background-tertiary) !important;
  max-width: 300px !important;
}

.market-nft:hover,
.market-nft-more:hover,
.market-nft-more-detail:hover {
  top: -5px;
  /* -webkit-filter: brightness(105%); */
  /* opacity: 0.9;
  transform: scale(1.01); */
}


@media only screen and (max-width: 576px) {
  .market-nft {
    width: 95%;
    /* filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15)); */
    border-radius: 8px;
  }
}
#profile-tabs .profiletabs.nav {
  flex-wrap: inherit;
}
.market-nft img,
.market-nft-more img {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-width: 2px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  aspect-ratio: 1/1 !important;
}

.market-nft .img-wrap {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.market-nft video,
.market-nft-more video {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-width: 2px;
  width: 100%;
  object-fit: cover;
  background-position: center;
  aspect-ratio: 1/1 !important;
}
.profile-nft-selected {
  border-color: black;
  filter: none !important;
}
.text-nft {
  white-space: pre;
  text-align: center;
  height: auto;
}
.text-nft-wrapper {
  height: 198px;
  display: flex;
}

.card-link {
  text-decoration: none;
  margin-left: 0;
}
.card-top-link {
  display: flex;
  text-decoration: none;
  margin-left: 0;
  align-items: center;
}

.info-labels {
  color: var(--text-secondary);
}

.salesTypeSaleRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #befda0 !important;
  /* background-color: #99f0f5; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  font-size: smaller;
}

.salesTypeAuctionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a0c0fd;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  font-size: smaller;
}

.salesTypeOfferRow {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8c15f;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: black;
  font-size: smaller;
}

.salesTypeSaleRow:hover {
  cursor: pointer;
}

.center-span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.mp-collection-alias:hover {
  color: var(--theme-main);
}

#mp-card-tooltip {
}