.dp {
  &__drop-info {
    &--container {
      display: grid;
      grid-template-columns: 1fr 40%;
      gap: 2rem;
    }

    &--content {
      display: grid;
      grid-template-rows: auto auto 1fr auto;
    }

    &--image {
      border-radius: 8px;
      overflow: hidden;
      background-color: #f5f5f5;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .dp {
    &__drop-info {
      &--container {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 1.5rem;
      }

      &--content{
        order: 2;
      }

      &--image {
        order: 1;
      }
    }
  }
}
