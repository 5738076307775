#profile-user-bar {
  /* min-width: 200px; */
  position: fixed;
  left: 0;
  display: block;
  z-index: 111;
  width: auto;
  height: 100vh;
  background-color: var(--background-navigation) !important;
  padding-top: 1.5rem;
}

@media screen and (max-height: 700px) {
  #profile-user-bar {
    position: absolute;
    height: 100%;
    max-height: 100vh !important;
  }
}

#profile-user-bar .nav-link {
  color: var(--text-primary);
  border-radius: 26px;
  margin-bottom: 5px;
  width: auto;
  /* display: inline-block !important; */
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#profile-user-bar .nav-link:hover {
  background-color: var(--theme-main);
  color: var(--text-primary-oppo);
  border-radius: 26px;
  opacity: 0.9;
  transition: all ease 0.3s;
}

#profile-user-bar .nav-link.active {
  background-color: var(--theme-main);
  color: var(--text-primary-oppo);
  border-radius: 26px;
}
#profile-user-bar img {
  width: 1.5rem;
  height: 1.5rem;
  /* margin-right: 10px; */
}

#profile-user-bar #drop-img{
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  /* margin-right: 10px; */
}

#profile-user-bar i {
  width: 1.5rem;
  height: 1.5rem;

  /* margin-right: 10px; */
}

#profile-user-bar .pub-text {
  margin-left: 15px;
}

.pub-sidebar-wrapper {
  /* min-width: 100px !important; */
}
