#royalty-mouseover-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  // max-width: calc(100vw - 1rem) !important;
  font-size: 1.2rem;
}

#col-detail-list-item {
  #list-item-title {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
  #list-item-desc {
    margin-bottom: 1rem;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
  #list-item-royalties {
    margin-bottom: 1rem;
    img {
      width: 3rem;
    }
  }
  #list-radio-wrapper {
    .list-radio {
      label {
        margin-left: 5px;
        margin-bottom: 2px;
        cursor: pointer;
        font-size: 0.9rem;
        color: var(--text-secondary) !important;
      }
      input {
        // width: 20px !important;
        // height: 20px !important;
        border-color: #d1fada !important;
      }

      input:checked {
        // background-color: var(--theme-main) !important;
        // border-color: var(--background) !important;
        //    width: 20px !important;
        // height: 20px !important;
        color: var(--theme-main);
        border-color: var(--theme-main) !important;
        background-color: #e4f5ee !important;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='3' cy='3' r='3' fill='%2358B792'/%3E%3C/svg%3E") !important;
        background-size: 6px;
      }

      input:hover{
        border-color: var(--theme-main) !important;
        opacity: 0.5;
      }
    }
  }

  #list-item-img {
    margin-bottom: 1rem;
    img {
      width: 250px;
      border-radius: 8px;
    }
  }
}

@media only screen and (max-width: 575px) {
  #col-detail-list-item {
    #list-item-royalties {
      margin-bottom: 1rem;
      img {
        width: 3rem;
      }
    }
    #list-item-img {
      margin-bottom: 1rem;

      img {
        width: 55%;
        border-radius: 8px;
      }
    }
  }
}
