.doodles__root {
  .dropdown {
    &-menu {
      background-color: white;
      color: #333;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      top: 8px;
      padding: 0;
    }

    &-item {
      background-color: white !important;
      color: #333;
      padding: 8px;

      &:hover,
      &.active {
        background-color: #ffd3e8 !important;
      }
    }
  }
}
