#footer-spacer{
    height: 429px !important;

}

@media only screen and (max-width: 768px) {
    #footer-spacer{
        height: 429px !important;
    
    }
  }


