@media only screen and (min-width: 1024px) {
  .doodles {
    &__root{
      padding-right: 1.5rem;
    }

    &__grid {
      max-width: 1600px;
      margin: 0 auto;
      grid-template-columns: auto 1fr;
      grid-template-areas:
      "sidebar selection"
      "sidebar results";
    }

    &__results {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .doodles {
    &__grid {
      max-width: 1600px;
      margin: 0 auto;
    }
    &__results {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
  }
}

@media only screen and (min-width: 640px) {
  body.no-scroll{
    height: auto;
    margin: 0;
    overflow: auto;
  }

  .doodles {
    &__results {
      --min: 30%;
      --max: 1fr;
    }
  }
}

@media only screen and (min-width: 420px) {
  .doodles {
    &__grid {

    }
    &__results {
      grid-template-columns: repeat(auto-fill, minmax(var(--min), var(--max)))
    }
  }
}

/*
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .doodles {
    &__grid {
    }
  }
}

@media only screen and (max-width: 768px) {
  .doodles {
    &__grid {
      min-height: auto;
    }

    &__results {
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .show-filters {
    .doodles__results {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (max-width: 480px) {
  .doodles {
    &__root {
      width: 100%;
      padding: 1rem;
    }

    &__grid {
      width: 100%;
      min-height: auto;
      grid-template-columns: 1fr;
      grid-template-areas: "selection" "results";
    }

    &__results {
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      padding-bottom: 2rem;
    }
  }
}

@media only screen and (max-width: 320px) {
  .doodles {
    &__results {
      gap: 0.5rem;
      grid-template-columns: 1fr;
    }
  }
}
*/
