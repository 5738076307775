#profile-thoughts-wrapper {
  #back-all-thoughts-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem !important;
    a {
      color: var(--text-secondary) !important;
      font-size: 0.9rem;
    }
  }
}
