@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap");

.dp {
  &__grey-container {
    padding: 1rem;
    background-color: #f6f6f6;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__space-between {
    align-items: center;
    justify-content: space-between;
  }

  &__align-end {
    justify-content: flex-end;
  }

  &__align-start {
    justify-content: flex-start;
  }

  &__mb {
    margin-bottom: 0.3rem;
    &-1 {
      margin-bottom: 0.75rem;
    }
    &-2 {
      margin-bottom: 1.5rem;
    }
    &-3 {
      margin-bottom: 2.5rem;
    }
  }

  &__gap {
    gap: 0.5rem;
    &-2 {
      gap: 1rem;
    }
    &-3 {
      gap: 1.5rem;
    }
  }

  &__roboto {
    font-family: Roboto, sans-serif;
  }

  &__small-text {
    font-size: 12px;
    color: #5c5c5c;
  }

  &__title {
    margin: 0 0 0.4em;
    font-family: Roboto, sans-serif;
    font-size: 28px;
    font-weight: 700;
  }

  &__paragraph {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    margin: 0 0 0.75em;
  }

  &__button {
    background-color: black;
    color: white;
    padding: 1em 1.5em;
    border-radius: 50px;
    text-transform: capitalize;
    min-width: 5em;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    cursor: pointer;
    border: none;
    &:active {
      background-color: #58b792;
    }
  }

  &__divider {
    background-color: #eaecf0;
    height: 1px;
    width: 100%;
  }
}

[data-theme="dark"] {
  .dp {
    &__grey-container {
      background-color: #4a524f;
    }

    &__small-text {
      color: #e3eae8;
    }

    &__button {
      background-color: #e3eae8;
      color: black;
      user-select: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dp {
    &__grey-container {
      display: grid;
      grid-template-columns: 100%;
    }
  }
}
