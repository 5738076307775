#charity-auction-outer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-profile);
  #charity-auction-wrapper {
    #charity-auction-banner-bio-wrapper {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem !important;
      .profile-pic {
        background-color: var(--background);
        height: 120px;
        width: 120px;
        border-radius: 50%;
        position: relative;

        img {
          height: 120px;
          width: 120px;
          border-radius: 50%;
          border: 1px solid #dedede;
          // box-sizing: border-box;
          padding: 0rem;
        }
      }
      .header-card-bg {
        background-size: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: 382px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      .header-name-padding {
        padding-top: 382px;
      }

      .header-square-col {
        margin-top: 336px;
      }
      #header-row {
        margin-bottom: 1rem;
      }

      #info-col {
        display: flex;
        flex-direction: column;
        #charity-page-info-section-desc-span {
          margin-bottom: 0.5rem;
          font-size: 1.1rem;
        }
      }
    }

    #charity-auction-gift-widget {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;

      #charity-auction-gift-widget-inner {
        display: flex;
        border-radius: 10px;
        width: 100%;
        background-color: var(--background);
        padding: 3rem;

        #gift-widget-img-col {
          img {
            border-radius: 10px;
            height: 20rem;
          }
        }
        #gift-widget-form-col {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
          // padding-right: 1rem;
          #gift-widget-title {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 1rem;
          }
          #gift-widget-desc {
            font-size: 1rem;
            font-weight: 500;
            color: var(--text-secondary);
            margin-bottom: 1rem;
          }

          #gift-widget-form {
            margin-top: auto;
            .form-item {
              display: flex;
              flex-direction: column;
              margin-bottom: 1rem;
              label {
                font-size: 0.8rem;
                color: var(--text-secondary);
                margin-bottom: 0.5rem;
              }
              input {
                border-radius: 8px;
                background-color: var(--background);
                color: var(--text-primary);
                border: 1px solid grey;
              }
              textarea {
                border-radius: 8px;
                background-color: var(--background);
                color: var(--text-primary);
                border: 1px solid grey;
              }
            }
            #form-button-row {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-top: auto;
            }
          }
        }
      }
    }

    #charity-auction-wall-wrapper {
      background-color: var(--background);
      // height: 100px;
      margin-top: 2rem;
      margin-bottom: 2rem;
      border-radius: 10px;
      #charity-auction-wall-upper {
        padding: 1rem;
        font-size: 2rem;
        font-weight: 500;

        #charity-auction-wall-title {
          font-size: 2rem;
          font-weight: 500;
        }
        #charity-auction-wall-desc {
          font-size: 1rem;
          font-weight: 500;
          color: var(--text-secondary);
        }
      }
      #charity-auction-wall-inner::-webkit-scrollbar {
        display: none;
      }

      #charity-auction-wall-inner {
        display: flex;
        flex-direction: column;
        max-height: 55vh;
        overflow: auto;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        #charity-auction-wall-item {
          // justify-content: space-between;
          display: flex;
          width: 100%;

          #charity-auction-wall-item-desktop {
            display: flex;
            width: 100%;
            padding: 2rem 1rem;
            border-bottom: 1px solid grey;
            #wall-item-from-col {
              display: flex;
              align-items: flex-start;
              width: 20%;
              font-weight: 500;
            }
            #wall-item-message-col {
              display: flex;
              align-items: flex-start;
              width: 60%;
              color: var(--text-secondary);
            }
            #wall-item-amount-col {
              display: flex;
              align-items: flex-start;
              width: 10%;
              font-size: 0.9rem;
              font-weight: 500;
              text-align: end;
            }
            #wall-item-date-col {
              display: flex;
              align-items: flex-start;
              margin-left: auto;
              color: var(--text-secondary);
              font-size: 0.8rem;
            }
          }
          #charity-auction-wall-item-mobile {
            display: none;
          }
        }
      }
      #powered-by-graffle-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
        color: var(--text-secondary);
        font-size: 0.8rem;
        img {
          height: 2rem;
          margin-bottom: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }

    #charity-auction-info-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      background-color: aquamarine;
      border-radius: 10px;

      margin-top: 2rem;
      margin-bottom: 2rem;
      #charity-info-title-row {
      }
      #charity-auction-desc-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        #charity-auction-desc-left-col,
        #charity-auction-desc-right-col {
          width: 100%;
          #charity-info-item {
            display: flex;
            flex: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  #charity-auction-gift-widget-inner {
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 1rem;
    }
    #gift-widget-form-col {
      padding-left: 0rem !important;
    }
  }
  #charity-auction-wall-item-desktop {
    display: none !important;
  }
  #charity-auction-wall-item-mobile {
    display: flex !important;
    width: 100%;
    padding: 2rem 1rem;
    border-bottom: 1px solid grey;
    #wall-item-from-col {
      width: 45%;
      font-weight: 500;
    }
    #wall-item-message-col {
      width: 55%;
      color: var(--text-secondary);
    }
    #wall-item-amount-col {
      color: var(--text-secondary);
      font-size: 0.9rem;
    }
    #wall-item-date-col {
      color: var(--text-secondary);
      font-size: 0.9rem;
    }
  }
}
