
    
    /* ~170 px more width needed split amongst 3 cols */
    /* largest break point done */

    /* .profile-width {
        max-width: 898px;
        width: 100%;
    } */
    /* .profile-col1-width {
        max-width: 300px;   
    }
    .profile-col3-width {
        min-width: 300px;
        max-width: 300px;
    }

    .logged-in-main-col-width {
        max-width: 1198px;
        width: 100%;
    } */

    .profile-table{
        table-layout: fixed;
        width: 100%;
    }

    .profile-names-table-names-row{
        height: 48px;
        
    }

    .profile-names-table-names-col{
        text-align: start;
        width: 25%;

    }

    .profile-names-table-sticker-col{
        text-align: center;
        width: 25%;

    }

    .profile-names-table-price-col{
        text-align: end;
        color: var(--text-secondary);
        width: 20%;


    }

    .profile-names-table-button-col{
        text-align: end;
        width: 30%;

        
    }

    .profile-sticker{
        font-size: 10px;
        background-color: var(--background-profile);
        border-radius: 20px;
        padding: 5px;
        color: var(--text-primary);
        font-weight: 500;

    }


    .profile-main-name-sticker{
        font-size: 10px;
        background-color: var(--background-profile);
        border-radius: 20px;
        padding: 5px;
        color: var(--text-primary);
        font-weight: 500;

    }

    .profile-expired-sticker{
        font-size: 10px;
        background-color:#e25822;
        border-radius: 20px;
        padding: 5px;
        color: #ffffff;
        font-weight: 500;

    }

    .profile-expires-in-sticker{
        font-size: 10px;
        background-color: var(--background-profile);
        background-color: #DDD228;
        border-radius: 20px;
        padding: 5px;
        color: #ffffff;
        font-weight: 500;
    }


    /* @media only screen and (max-width: 1420px) {
    .profile-width {   
        width: 100%;
        max-width: 728px;
    }

    .logged-in-main-col-width {
        max-width: 978px;
        width: 100%;
    }

    .profile-col3-width {
        min-width: 250px;
        max-width: 250px;
    }
    }
    @media only screen and (max-width: 1200px) {
        .profile-width {   
            width:min-content;
            min-width: 500px;
            max-width: none;
        }

        .logged-in-main-col-width {
            width:min-content;
            min-width: 750px;
            max-width: none;
        }
        .profile-col1-width, .profile-col3-width {
    
            max-width: 250px;
        }
    }
    @media only screen and (max-width: 990px) {
        .profile-width {   
            width: 100%;
        }

        .logged-in-main-col-width {
            width: 100%;
        }
        
        .profile-col1-width, .profile-col3-width {
            max-width: 250px;
        }
        } */
    @media only screen and (max-width: 576px) {
        .profile-width {   
            width: 100%;
            min-width: 300px;
        }

        .logged-in-main-col-width {
            min-width: 300px;
            width: 100%;
        }
        .profile-col1-width, .profile-col3-width {
            max-width: 250px;
        }
        }

        .profile-names-table-row{
            padding-top: 1rem;
            padding-bottom: 1rem;

        }

        .profile-mobile-row{
            display: none;
        }

        .profile-dropdown{
            display: table-cell ;
        }

        .profile-mobile-dropdown{
            display: none;
        }
        
        @media screen and (max-width:1200px) {
            .profile-dropdown{
                display: none ;
            }
        .profile-mobile-dropdown{
            display: table-cell ;
        }
            .profile-mobile-row{
                display:contents;
            }
            .profile-names-table-names-col{
            width: auto;
                }
        
                .profile-names-table-button-col{
                display: none;
                }   
                
                .profile-sticker{
                    display: none;
                }
        
            .profile-names-table-sticker-col{
                display: none;
            }

            .profile-names-table-price-col{
                text-align: end;
                width: auto;
            
            }
        
            
          .store-time-left-col{
            display:none;
            }

            .sticker-wrapper {
                display: none;
            }
          
            .mobile-store-sticker-col{
            }
        
            .mobile-store-time-left-col{
                text-align: start;
            }
        
            .mobile-store-buttons-col{
                text-align: end;
        
            }}
        