.pack-open-center{
  min-height: 30rem;
}

.pack-reward-grid{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 1rem auto 0;
}

.pack-reward-block{
  width: 40%;
  text-decoration: none;
  display: block;

  img{
    display: block;
    width: 100%;
    height: auto;
    background-color: rgba(100,100,100,0.1);
    aspect-ratio: 1;
    border-radius: 6px;
    overflow: hidden;
  }

  p{
    text-align: center;
    margin: 0.25rem;
  }
}

#find-pack-modal-wrapper {

  .pack-thumbnail{
    width: 90%;
    height: auto;
    aspect-ratio: 1;
    margin: 0 auto 1rem;
    background-color: var(--background);
  }

  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem;
  justify-content: center;

  #pack-img {
    height: 82%;
    border-radius: 8px;
  }

  #pack-img-loading {
    width: 80%;
    height: auto;
    // border-radius: 50%;
    // position: absolute;
    // animation: bounce 1s linear infinite;
    background-color: grey;
  }

  #pack-open-title {
    font-size: 2rem;
    color: #58b792;
    margin:0.25em 0 0.5em;
  }

  #pack-open-id {
    font-size: 0.9em;
    color: var(--text-primary);
  }

  a{
    text-decoration: none;
  }

  #item-info-col{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// @keyframes bounce {
//   0%,
//   100%,
//   20%,
//   50%,
//   80% {
//     -webkit-transform: translateY(0);
//     -ms-transform: translateY(0);
//     transform: translateY(0);
//   }
//   40% {
//     -webkit-transform: translateY(-30px);
//     -ms-transform: translateY(-30px);
//     transform: translateY(-30px);
//   }
//   60% {
//     -webkit-transform: translateY(-15px);
//     -ms-transform: translateY(-15px);
//     transform: translateY(-15px);
//   }
// }