#update-profile-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 86px);
  text-align: center;
  font-family: "Baloo Paaji 2";
  font-weight: 600;
  margin-top: -10vh;
  #update-profile-title-span {
    font-size: 2rem;
  }
  #update-profile-title-span {
    margin-bottom: 1rem;
    width: 80%;
  }
  #update-profile-info-span {
    font-size: 1rem;
    width: 30%;
    color: var(--text-secondary);
  }
}

@media only screen and (max-width: 992px) {
  #update-profile-info-span {
    font-size: 1rem;
    width: 70% !important;
    color: var(--text-secondary);
  }
}
