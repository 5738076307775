.search-wrapper-box{
    position: relative;
}

.main-search{

}

.search-icon {
    height: 25px;
    width: 25px;
    background-color: var(--background) !important ;
    padding: 4px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    left:2px;
    transform: translateY(-50%);
    margin-left: 1rem;
  }
  
  .search-input {
    height: 40px;
    padding-left: 40px !important;
    box-sizing: border-box;
  }

  .search-input::placeholder {
    color: var(--text-primary) !important;
    font-size: small !important;
   
  }