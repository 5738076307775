/* #names-owned-accordion img {
    margin-right: 5px;
} */
#names-owned-accordion .card, .card-header {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 0px;
    padding-right: 0px;
}

.noBorder {
    border: none;
  }