.DefaultProgressBar_progressBar {
    background-color: var(--theme-main);
    position: absolute;
    height: 10px;
    border-radius: 10px;
    margin-top: -2px;
}

.DefaultHandle_handle {
    width: 24px;
    height: 24px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    background-color: #fcfcfc;
    border-radius: 50%;
    outline: none;
    z-index: 2;
    box-shadow: none;
    margin-top: -2px;
    margin-left: -10px;
}

.DefaultHandle_handle:before {
    content: "";
    display: none;
    position: absolute;
    background-color: #dadfe8;
}

.DefaultHandle_handle:after {
    content: "";
    display: none;
    position: absolute;
    background-color: #dadfe8;
}

.handleContainer {
    height: 10px;
    top: -2px;
    left: -2px;
    bottom: 4px;
    width: 100%;
    position: absolute;
    border-radius: 20%;
}

.DefaultBackground {
    background-color: #D6D6D6;
    height: 15px;
    width: 100%;
    border: 1px solid #d8d8d8;
    position: relative;
    border-radius: 10px;
}

.DefaultBackground_background__horizontal {
    height: 10px;
    top: -2px;
    left: -2px;
    bottom: 4px;
    width: 100%;
}

.slider-input{
    width: 68px;
    background-color: var(--background  );
    color: var(--text-primary);
    border: none;
    border-radius: 4px;
    outline: none;
    font-size: small;
}

.slider-input:focus, .slider-input:focus-visible{
    width: 68px;
    background-color: var(--background  );
    color: var(--text-primary);
    border: none;
    border-radius: 8px;
        outline: none;
}

.accordion-body--wide,
.accordion-body--wide .px-4{
    padding: 8px 0.5rem !important;
}

.accordion-body--wide .px-4{
    padding: 0;
}

.accordion-body--wide .mt-2.row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}