#sale-nft-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: top ease 0.2s;
  position: relative;
  top: 0;
  // height: 400px;
  margin-bottom: 1rem;
  cursor: pointer;

  #sale-nft-upper-wrapper {
    width: auto;
    height: fit-content;
    position: relative;

    img {
      // max-width: 100%;
      // max-height: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      aspect-ratio: 1/1 !important;
      max-width: 100%;
      display: block;
    }
    video {
      // max-width: 100%;
      // max-height: 100%;
      //https://stackoverflow.com/questions/49907306/strange-gap-at-the-bottom-of-the-html-video-element-when-using-object-fit-cove - display block
      display: block;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      aspect-ratio: 1/1 !important;
      max-width: 100%;
    }
    .tag {
      padding: 6px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 16px;
      height: 22px;
      align-items: center;
      display: flex;
    }

    .for-sale {
      background-color: #eff8ff;
      color: #175cd3;
      margin-right: 5px;
    }

    .auction {
      background-color: #f4f3ff;
      color: #5925dc;
    }
  }

  #sale-nft-lower-wrapper {
    border-bottom: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid transparent;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    // height: 8rem;
    padding: 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    #sale-nft-lower-name {
      font-weight: 700;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      height: 3rem;
      // height: 2rem;
    }
    #sale-nft-lower-amount {
      color: var(--text-secondary);
      font-weight: 600;
      margin-top: auto;
      font-size: 0.9rem;
      height: 2rem;
      padding-top: 10px;
    }
  }
}

#sale-nft-wrapper:hover {
  top: -5px;
  /* -webkit-filter: brightness(110%); */
  cursor: pointer;
}
