.dp {
  &__project-status {
    color: #3d9b76;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    margin-bottom: 1rem;

    span {
      font-weight: bold;
      margin-right: 0.5rem;
    }

    &--live {
      text-transform: uppercase;
      font-weight: 600;
    }

    &--closed{
      color: #ccc;
    }
  }
}
