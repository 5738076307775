.doodles__grid {
  .filter {
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: all 0.5s ease-in-out, opacity 0.25s ease-in-out;

    align-self: flex-start;
    grid-area: sidebar;
    align-items: start;

    .ais-RefinementList-count {
      margin-left: 20px;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9000;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    background-color: white;

    &__container {
      border-radius: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      header {
        background-color: #a4a4f4;
        padding: 12px 20px;
        color: white;
        font-size: 18px;
        margin: 0;
        border-radius: 16px 16px 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        span {
          color: #ffe871;
        }
      }

      input[type="text"],
      input[type="number"] {
        border: none;
        padding: 10px 16px;
        font-family: Chalkboard, cursive;
        border-radius: 8px;
        color: #5b5ba5;
        font-size: 18px;
        width: 100%;
        outline: 2px solid rgba(255, 255, 255, 0);
        transition: all 0.25s ease-in-out;
        margin-bottom: 16px;

        &::selection {
          background-color: #a6a6f0 !important;
          color: white;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:focus {
          outline: 2px solid #a4a4f4;
          box-shadow: 0 4px 7px rgba(0 0 0 / 15%);
        }
      }

      button {
        cursor: pointer;
        font-family: inherit;
        border: none;
        background-color: #9fc3f9;
        color: white;
        padding: 8px 24px;
        border-radius: 32px;
        box-shadow: 0 4px #92b0f4, 0 8px #8dc9ff;
        font-size: 20px;

        &:disabled {
          background-color: #a4a4f4;
          color: #ccf0ff;
        }
      }
    }

    &__content {
      width: 100%;
      background-color: #9bd6fd;
      padding: 20px;
      color: white;
      border-radius: 0 0 16px 16px;
      min-height: 300px;
      position: relative;
      margin-bottom: 1.5rem;

      h3 {
        margin: 0;
        font-size: 18px;
        margin-bottom: 0.25em;
        font-weight: normal;
      }

      &:after {
        content: "";
        display: block;
        width: 90%;
        background-color: #8dc9ff;
        height: 10px;
        position: absolute;
        top: 100%;
        left: 5%;
        border-radius: 0 0 16px 16px;
      }

      &__group {
        h3 {
          margin-bottom: 0.5rem;
        }
        &:after {
          content: "";
          display: block;
          margin: 1rem 0;
          background-color: white;
          height: 2px;
          border-radius: 2px;
          opacity: 0.25;
        }
      }
    }

    &__search-title {
      display: flex;
      justify-content: space-between;
      span {
        display: inline;
        color: yellow;
      }
    }

    &__search-wrapper {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: all ease-in-out 0.25s;

      &:has(input:focus) {
        transform: translate3d(0, -2px, 0);
      }

      input::placeholder {
        color: #ccc;
      }
    }

    &__round-button {
      box-sizing: border-box;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #bedbec !important;
      box-shadow: 0 2px #98bbcf !important;
      padding: 0 !important;
      transform: translate3d(0, -1px, 0);
      transition: all ease-in-out 0.25s;

      &:hover {
        transform: translate3d(0, -3px, 0);
        background-color: #99d6fa !important;
        box-shadow: 0 4px 2px #98bbcfaa !important;
      }

      &--violet {
        font-size: 14px !important;
        width: 24px;
        height: 24px;
        background-color: #cfbefa !important;
        box-shadow: 0 2px #ac9dd5 !important;
      }
    }

    &-checkbox {
      display: inline-flex;
      gap: 10px;
      align-items: center;

      .form-check-input {
        height: 24px;
        width: 24px;
        margin: 0;
        border: none;
        appearance: none;
        background-color: white;

        &:checked {
          background-color: #ec7cb7;
          border: none;
        }

        &:focus {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 15%);
        }
      }

      label {
        display: block;
        user-select: none;
      }
    }

    &__options-list {
      padding: 0.5rem 1rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__checkbox-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span {
        --size: 30px;
        font-weight: bold;
        width: var(--size);
        height: var(--size);
        background-color: #80b1ff;
        display: grid;
        place-items: center;
        border-radius: 32px;
        font-size: 12px;
        box-shadow: 0 2px #6d96d8;
      }
    }

    &__slider-container {
      .accordion-body {
        padding: 20px !important;
      }

      h3 {
        margin-bottom: 1em;

        &:after {
          content: ":";
        }
      }

      .slider-container__input-group {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;

        .slider-input {
          padding: 4px 6px;
          margin-bottom: 0;
          background-color: white;
        }
      }

      .handleContainer button {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        padding: 0;
        background-color: white;
        border: 3px solid #ec7cb7;
        box-shadow: none;
        transform: translate3d(0, -1px, 0);
        transition: border 0.5s ease-in-out;

        &:focus,
        &:active {
          outline: 4px solid #ec7cb7;
          border-color: #cf6d9b;
        }
      }

      .rheostat {
        margin-bottom: 16px;
      }

      .DefaultBackground {
        background-color: white;
        border: none;
      }

      .DefaultProgressBar_progressBar {
        background-color: #ec7cb7;
      }
    }

    .accordion-item {
      background-color: transparent;
      border: none;
      margin-bottom: 10px;
    }
    .accordion-body {
      max-height: 300px;
      overflow: auto;
    }
    .accordion-header {
      .accordion-button {
        width: 100%;
        border-radius: 24px;
        padding: 10px 16px;
        background-color: #80c0f5 !important;
        box-shadow: 0 2px #5c9ccb;

        &:after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
        }

        &[aria-expanded="true"] {
          color: white;
          background-color: #ef81b5 !important;
        }

        span {
          font-size: 16px !important;

          font-family: Chalkboard, cursive;
        }

        &:last-of-type {
          border-radius: 20px;
        }
      }
    }

    // Scrollbar
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: #e9f9ff55;
      border-radius: 8px;
      overflow: hidden;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #e9f9ff;
      border-radius: 8px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.show-filters {
  .filter {
    opacity: 1;
    width: 100%;
    transition: all 0.5s;
    pointer-events: auto;
    overflow-y: auto;
    background-color: white;
  }
}
