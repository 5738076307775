#register-name-box {
    background-color: var(--background);
    border-radius: 10px;
    box-shadow: var(--shadow);
    border: 1px solid rgba(0,0,0,.125);
    margin-bottom: 1rem;
}

.dark-text {
    color: var(--text-primary);
}

.theme-accent-text{
    color: #58B792;
}

.disclaimer {
    width: 100%;
    font-size: 0.8rem;
    color: var(--text-secondary);
}