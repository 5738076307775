.singles-grid{
  padding: 20px;
  background-color: var(--background-tertiary);
  border-radius: 8px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;

  .trait{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    font-size: 12px;

    p{
      margin: 0;
    }

    span{
      margin-right: 1em;
      font-weight: bold;
      &:after{
        content: ":";
      }
    }
  }
}

.group-head{
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.25em;
  &:after{
    content: ":"
  }
}

.level-0{
  font-size: 24px;
}

.level-1{
  font-size: 18px;
}

.level-2{
  font-size: 16px;
}

.level-3{
  font-size: 14px;
}

.long-value{
  word-break: break-all;
}