#fund-widget .accordion-item {
    border-radius: 10px !important;
    background-color: var(--background);
    box-shadow: var(--shadow);
}
#fund-widget .accordion-button.collapsed {
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-primary);
}
#fund-widget .accordion-button {
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text-primary);
}
#fund-widget .accordion-button:focus {
    border: none;
    box-shadow: none;
}
#fund-widget .accordion-button::after {
    background-image: var(--accordion-chevron)
}
#fund-widget {
    /* max-width: 300px; */
}

#fund-widget-warning-icon-row{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    padding-right: 5px;
}

#fund-widget-warning-icon-row i{
    cursor: pointer;
}