.mp-mobile-side-bar{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background-color: var(--background);
    top:        0;
    bottom:     0;
    left:       0;
    right:      0;
    overflow: hidden;
    transition: 1s ease-in-out;

}

.mp-mobile-side-bar-hidden{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10000;
    background-color: var(--background);
    top:        0;
    bottom:     0;
    left:       -100%;
    right:      0;
    overflow: hidden;
    transition: 1s ease-in-out;
    visibility:hidden;
    width: 0px !important;
    padding: 0 !important;
    margin: 0 !important;
    transition: 1s ease-in-out;

}



#mp-mobile-side-bar .filters-title {
display: flex;
align-items: center;
background-color: var(--background) !important;
}



#show-filter-label {
    width: 24px;
    height: 24px;
    background-image: var(--mp-filter);
    margin-right: 8px;
    cursor: pointer;
}

#mp-mobile-side-bar #scrollable-accordion{
    overflow: auto;
        max-height: 98%;
}


#mp-mobile-side-bar .input, #mp-mobile-side-bar select, #mp-mobile-side-bar select{
    background-color: var(--background-profile) !important;
    border: none !important;
    height: 60px !important;
    border-radius: 10px !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}

#mp-mobile-side-bar i{
    background-color: var(--background-profile) !important;

}

#mp-mobile-side-bar .mobile-i{
    background-color: var(--background) !important;

}

#mp-mobile-side-bar .slider-input{
    margin-top: 1rem;
    background-color: var(--background-profile) !important;
    width: 100px !important;
    height: 50px;
    text-align: center;

}

#mp-mobile-side-bar .input:focus, #mp-mobile-side-bar .input:focus-visible, #mp-mobile-side-bar  select:focus{
    background-color: var(--background-profile) !important;
    border: none !important;
    height: 60px !important;
    border-radius: 10px !important;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: var(--text-primary);
    padding-left: 15px;
    box-shadow: none !important;
}



@media only screen and (min-width: 576px) {
   
    #mp-mobile-side-bar{
        visibility:hidden;
        width: 0px !important;
        height: 0px !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}