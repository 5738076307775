.form-check-input:checked {
    background-color: var(--theme-main);
    border-color: var(--theme-main);
}


.form-check-input:focus {
    border-color: var(--theme-main);
    outline: 0;
    box-shadow: none;
}

.form-check-input{
    cursor: pointer;
    background-color: var(--background);
}

.form-label{
    cursor: pointer;

}

.check-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    padding-left: 1rem;
}

.check-box:hover{
   background-color: var(--theme-main);
   cursor: pointer;
   opacity: 0.8;
   transition: all ease 0.3s;
}


    