#mp-bought-success-modal{
    text-align: center;
}

#mp-bought-success-modal .successHeader{
    text-align: center;
    padding-bottom: 0;
}

#mp-bought-success-modal .modal-img {
    border-radius: 8px;
    border-width: 2px;
    width: 100%;
    object-fit:cover;
    background-position:center;
    aspect-ratio: 1/1 !important;
    margin-bottom: 1rem;
}


#mp-bought-success-modal .modalText{
    color: var(--text-secondary);
}
.modalFooterSocials {
    font-size: 20px;
    color: var(--text-primary);
}




.successHeader {
    text-align: center;
    border: none;
}