.doodles {
  &__filter-selection {
    grid-area: selection;
    display: grid;
    grid-template-rows: auto;
    gap: 16px;
  }

  &__filters-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    grid-row: 2;
    flex-wrap: wrap;
    transition: all 0.5s ease-in-out;
    height: 100%;
  }

  &__sorting {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row: 1;
    row-gap: 1rem;

    button {
      flex: 0 0 auto;
      justify-self: flex-start;
      padding: 0.5rem 1.5rem;
      border: none;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 2px #6d96d8;
      box-shadow: 0 2px 2px #6d96d8;
      color: #6d96d8;
      margin-right: 10px;
      transition: all ease-in-out 0.15s;
      animation: slide-in 0.25s;
    }

    button:hover {
      transform: translate3d(0, -2px, 0);
      box-shadow: 0 4px 2px #6d96d8;
    }

    button:active {
      transform: translate3d(0, 1px, 0);
      box-shadow: 0 1px 2px #6d96d8;
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: white;
    }

    &--dropdown {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }

    &__toggle {
      background-color: transparent;
      font-weight: 400;
      color: #086489;
    }

    &__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}