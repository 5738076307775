#mobile-profile-user-bar {
  /* min-width: 200px; */
  display: block;
  z-index: 10;
  width: auto;
  background-color: var(--background-navigation) !important;
  padding-top: 1.5rem;
}

#mobile-profile-user-bar .nav-link {
  color: var(--text-primary);
  border-radius: 26px;
  margin-bottom: 5px;
  width: auto;
  /* display: inline-block !important; */
  padding-left: 10px !important;
  padding-right: 10px !important;
}

#mobile-profile-user-bar .nav-link:hover {
  background-color: var(--theme-main);
  color: var(--text-primary-oppo);
  border-radius: 26px;
  opacity: 0.9;
  transition: all ease 0.3s;
}

#mobile-profile-user-bar .nav-link.active {
  background-color: var(--theme-main);
  color: var(--text-primary-oppo);
  border-radius: 26px;
}
#mobile-profile-user-bar img {
  width: 1.5rem;
  height: 1.5rem;
  /* margin-right: 10px; */
}

#mobile-profile-user-bar #drop-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50% !important;
  /* margin-right: 10px; */
}

#mobile-profile-user-bar i {
  width: 1.5rem;
  height: 1.5rem;

  /* margin-right: 10px; */
}

#mobile-profile-user-bar .pub-text {
  margin-left: 15px;
}

.pub-sidebar-wrapper {
  /* min-width: 100px !important; */
}
